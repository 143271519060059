import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import Loader from "../../Components/loader";

function ChangePassword() {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPasswordVisiblity, setConfirmPasswordVisiblity] = useState(false);
  const [passwordVisiblity, setPasswordVisiblity] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();


  const forgot = async (e) => {
    e.preventDefault();
    let obj = {
      password: password,
      password_confirmation: confirmPassword,
    };

    if(password != confirmPassword){
      ToastMessage.Error("Password doesn't match");
      return
    }

    try {
      setLoading(true);
      let resp = await FetchApi(Endpoints.ChangePassword, obj);
      if (resp && resp.status === "success") {
        ToastMessage.Success(resp.message);
        history("/profile");
      }
      if(resp && resp.status === "false"){
        ToastMessage.Error(resp.message);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("forgotErr__", JSON.stringify(e.response, null, 4));
      ToastMessage.Error(e.message);
    }
  };
  return (
    <div className="main py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <h1 className="text-darkgray semibold pb-2 text-center">
                Change Password
            </h1>
            <p className="text-green2 text-center mb-3">
              Your new password must be different from previously used
              passwords.
            </p>

            <form onSubmit={forgot} className="site-form pt-2">
              <div className="form-field mb-4 pwd-group position-relative">
                <label htmlFor="" className="pb-1">
                  New Password
                </label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  type={passwordVisiblity ? "text" :"password"}
                  placeholder="New Password"
                  className="pwd-field"
                />
                <a
                    onClick={(e)=>{
                        setPasswordVisiblity(!passwordVisiblity)
                    }}
                  className="pwd-visibility text-gray2 position-absolute "
                >
                  <i className={passwordVisiblity ? 'fa-light fa-eye-slash' : 'fa-light fa-eye'}></i>
                </a>
              </div>
              <div className="form-field mb-2 pwd-group position-relative">
                <label htmlFor="" className="pb-1">
                  Confirm New Password
                </label>
                <input
                  onChange={(e) => {
                    setConfirmPassword(e.target.value)
                }}
                  type={confirmPasswordVisiblity ? "text" :"password"}
                  placeholder="Confirm New Password"
                  className="pwd-field"
                />
                <a
                  onClick={(e)=>{
                    setConfirmPasswordVisiblity(!confirmPasswordVisiblity)
                }}
                  className="pwd-visibility text-gray2 position-absolute "
                >
                  <i className={confirmPasswordVisiblity ? 'fa-light fa-eye-slash' : 'fa-light fa-eye'}></i>
                </a>
              </div>
              <button type="submit" 
              disabled={!confirmPassword || !password 
                // || (confirmPassword && password && confirmPassword != password)
              }
                className="butn w-100 rounded-pill border-0"
              >
                {loading? <Loader isButton={true}/> : "Submit"}
              </button>
            </form>

         
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
