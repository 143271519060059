import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import Loader from "../../Components/loader";

function SetNewPassword() {
  let data = useLocation();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  let ss = data.state;
  const forgot = async (e) => {
    e.preventDefault();
    if(password != confirmPassword){
      ToastMessage.Error("Password and confirm password must be match");
      return;
    }
    let obj = {
      email: ss.email,
      stage: "change",
      otp_token: ss.otp,
      password: password,
      password_confirmation: confirmPassword,
    };

    try {
      setLoading(true);
      let resp = await FetchApi(Endpoints.forgotPassword, obj);
      if (resp && resp.status === "success") {
        // history("/thanksforregister");
        ToastMessage.Success(resp.message);
        history("/Login");
      }
      if(resp && resp.status === "false"){
        ToastMessage.Error(resp.message);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("forgotErr__", JSON.stringify(e.response, null, 4));
      ToastMessage.Error(e.message);
    }
  };
  return (
    <div className="main py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <h1 className="text-darkgray semibold pb-2 text-center">
              Set New Password
            </h1>
            <p className="text-green2 text-center mb-3">
              Your new password must be different from previously used
              passwords.
            </p>

            <form onSubmit={forgot} className="site-form pt-2">
              <div className="form-field mb-4 pwd-group position-relative">
                <label htmlFor="" className="pb-1">
                  New Password
                </label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="New Password"
                  className="pwd-field"
                />
                <a
                  href="#!"
                  className="pwd-visibility text-gray2 position-absolute "
                >
                  <i className="fa-light fa-eye"></i>
                </a>
              </div>
              <div className="form-field mb-2 pwd-group position-relative">
                <label htmlFor="" className="pb-1">
                  Confirm New Password
                </label>
                <input
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  placeholder="Confirm New Password"
                  className="pwd-field"
                />
                <a
                  href="#!"
                  className="pwd-visibility text-gray2 position-absolute "
                >
                  <i className="fa-light fa-eye"></i>
                </a>
              </div>
              <button type="submit" 
              disabled={!confirmPassword || !password}
              className="butn w-100 rounded-pill border-0"
              >
                {loading? <Loader isButton={true}/> : "Reset Password"}
              </button>
            </form>

            <div className="text-center pt-3">
              {/* <a href="#" className="text-green py-3 medium"> */}
              <Link to={"/Login"}>Back to Login</Link>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetNewPassword;
