import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import moment from "moment";
import Swal from "sweetalert2";
import LocationAutocomplete from "../../Components/LocationAutocomplete"
import { connect } from 'react-redux';
import CreateAvailabilityModal from "../../Components/createAvailability";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CustomToolbar from "../../Components/CustomToolBar";
import ShareModal from "../../Components/ShareModal";
import FacilityCoachModal from "../../Components/FacilityCoachModal";
import CreateReferAvailability from "../../Components/CreateReferAvail";

const $ = window.jQuery;

const localizer = momentLocalizer(moment)

function CheckOthersAvailability(props) {
  const params = useParams();
  // console.log('propsssss__', props);
  console.log('paramssssluuggg__', params?.slug);
  const history = useNavigate();
  const [availabilityList, setAvailabilityList] = useState([]);
  const [selectedIndex, setSelectedLocation] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [markedAvailModal, setMarkedAvailModal] = useState(false);
  const [clickedEventDt, setCLickedEventDt] = useState({});
  const [userAvailablity, setUserAvailability] = useState([]);
  const [user, setUserInfo] = useState(props?.user)
  const [kidId, setKidId] = useState('');
  const [kidInfo, setKidInfo] = useState(null);
  const [kidsList, setKidsList] = useState([]);
  const [currentView, setCurrentView] = useState('month');
  const [eventDetails, setEventDetails] = useState({
    is_marked_availability: 0,
    id: null,
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: ''
  });

  const getAvailability = async () => {
    try {
      let resp = await FetchApi(Endpoints.getUserAvailability, { user_slug: params.slug });
      if (resp && resp.type === "success") {
        setUserAvailability(resp?.data);
      }
    }
    catch (e) {
    }
  }

  const getKidsList = async () => {
    try {
      let resp = await FetchApi(Endpoints.getUserKids);
      if (resp && resp.type === "success") {
        const kidsList = resp.data
        if (kidsList.length > 0) {
          let data = kidsList[0].user_kids;
          if (data.length > 0) {
            data.push({
              id: 'self',
              name: 'Self'
            })
          }
          setKidsList(data)
        }
      }
    }
    catch (e) {
    }
  }

  // const getEventSegments = (event) => {
  //   const segments = [];
  //   let currentStart = moment(event.start);
  //   const endDateTime = moment(event.end);
  
  //   while (currentStart.isBefore(endDateTime)) {
  //     const nextEnd = currentStart.clone().endOf('day');
  //     segments.push({
  //       ...event,
  //       start: currentStart.toDate(),
  //       end: nextEnd.isBefore(endDateTime) ? nextEnd.toDate() : event.end,
  //       additionalParams: {
  //           duplicateStartTime: event.start,
  //           duplicateEndTime: event.end,
  //       },
  //     });
  //     currentStart = currentStart.clone().add(1, 'day').startOf('day');
  //   }
  //   return segments;
  // };

  const getEventSegments = (event) => {
    const segments = [];
    let currentStart = moment(event.start);
    const endDateTime = moment(event.end);

    const differenceInDays = endDateTime.diff(currentStart, 'days');

    const startTime = moment(event?.start);
      const endTime = moment(event?.end);

    const repeatDays = event?.repeat_days?.split(',')?.map(day => day?.trim()?.toLowerCase());
    
    // let coachBookingCount = 0;
    // let athleteBookingCount = 0;

    // event?.user_bookings?.forEach(booking => {
    //   if (booking.availability_date === moment(event.start)?.format('YYYY-MM-DD')) {
    //     console.log('checkisEqual__', booking.availability_date === moment(event.start)?.format('YYYY-MM-DD'))
    //     if (booking.user.user_role?.id == 3) {
    //       coachBookingCount++;
    //     } else if (booking.user.user_role.id == 2) {
    //       athleteBookingCount++;
    //     }
    //   }
    // });

    while (currentStart.isBefore(endDateTime)) {
      if (event?.recurrences=="weekly" && 
        repeatDays?.includes?.(currentStart?.format('ddd')?.toLowerCase())
        && differenceInDays>7
      ) {
      const nextEnd = currentStart.clone().endOf('day');
      segments.push({
        // ...event,
        // start: currentStart.toDate(),
        // end: nextEnd.isBefore(endDateTime) ? nextEnd.toDate() : event.end,
        ...event,
              start: currentStart.clone().set({
                hour: startTime.hour(),
                minute: startTime.minute(),
                second: startTime.second(),
              }).toDate(),
              end: currentStart.clone().set({
                hour: endTime.hour(),
                minute: endTime.minute(),
                second: endTime.second(),
              }).toDate(),
        
        // coach_booking_count: coachBookingCount,
        // athelete_booking_count: athleteBookingCount,
      });
      }
      else if (event?.recurrences!="weekly" || differenceInDays<8){
        const nextEnd = currentStart.clone().endOf('day');
        segments.push({
          ...event,
          // start: currentStart.toDate(),
          // end: nextEnd.isBefore(endDateTime) ? nextEnd.toDate() : event.end,
          start: currentStart.clone().set({
            hour: startTime.hour(),
            minute: startTime.minute(),
            second: startTime.second(),
          }).toDate(),
          end: currentStart.clone().set({
            hour: endTime.hour(),
            minute: endTime.minute(),
            second: endTime.second(),
          }).toDate(),
          
          // coach_booking_count: coachBookingCount,
          // athelete_booking_count: athleteBookingCount,
        });
      }
      currentStart = currentStart.clone().add(1, 'day').startOf('day');
    }
    return segments;
  };

  const updateBookingCounts = (segments, userBookings) => {
    return segments.map(segment => {
      let coachBookingCount = 0;
      let athleteBookingCount = 0;
      let booked = false;
  
      userBookings.forEach(booking => {
        if (booking.availability_date === moment(segment.start).format('YYYY-MM-DD')) {
          if(segment?.slot_type == "fully" && !booking?.cancelled_at){
            booked = true
          }
          if (booking.user.user_role?.id == 3 && !booking?.cancelled_at) {
            coachBookingCount++;
          } else if (booking.user.user_role?.id == 2 && !booking?.cancelled_at) {
            athleteBookingCount++;
          }
        }
      });

      if(segment?.confirmed_refer_availabilities?.length>0){
        // console.log('check__', segment?.confirmed_refer_availabilities?.length)
        coachBookingCount = coachBookingCount + segment?.confirmed_refer_availabilities?.length;
      }
  
      return {
        ...segment,
        coach_booking_count: coachBookingCount,
        athelete_booking_count: athleteBookingCount,
        is_booked: booked
      };
    });
  };

  const formattedData =
      userAvailablity?.map((item) => ({
          title: item?.event_name,
          start: new Date(`${item?.slot_start_date}T${item?.slot_start_time}.0000000`),
          end: new Date(`${item?.slot_end_date}T${item?.slot_end_date_time}.0000000`),
          id: item?.id,
          ...item
      }));

  const segmentedEvents = formattedData?.flatMap((event) => getEventSegments(event));

  const segmentedEventsWithBookingCounts = segmentedEvents.flatMap(segment => 
    updateBookingCounts([segment], segment.user_bookings)
  );

  // console.log('segmented_eve___', segmentedEventsWithBookingCounts)

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  const handleSelectEvent = (event) => {
    // console.log('evet__________', event)
    setCLickedEventDt(event);
    const { start, end } = event;

    const now = new Date();

    if (start < now) {
      return;
    }
    if((props?.user?.user_role?.id==2 && event?.max_athlete && (event?.max_athlete == event?.athelete_booking_count)) 
      || 
      (props?.user?.user_role?.id==3 && event?.max_coaches && (event?.max_coaches == event?.coach_booking_count)) 
      || 
      (event?.slot_type == 'fully' && event?.is_booked)
    ){
      ToastMessage.Error("Slots are not available");
      return
    }
    localStorage.setItem('eventData', JSON.stringify(event));
    if(props?.user?.user_role?.id==3 && event?.slot_type!="fully"){
      if(event?.is_marked_availability){
        if(event?.refer_availabilities.filter((item)=> item?.user_id == props?.user?.id)?.length>0){
          ToastMessage.Error('Terms already added. You can check it on your "Manage Availabilty" section');
        }
        else{
          setMarkedAvailModal(true);
        }
      }
      else{
        setModalIsOpen2(true);
      }
    }
    else if(props?.user?.user_role?.id == 2 && kidsList?.length >0){
      $("#coach_availability").modal("show");
    }
    else{
      localStorage?.setItem('slug_local', params?.slug);
      history("/booking-summary", {state: {slug: params?.slug}});
    }
  };

  useEffect(() => {
    getAvailability();
    getKidsList();
  }, []);

  const CustomDateCellWrapper = ({ value, children }) => {
    const isPast = value < new Date();
    return React.cloneElement(React.Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: isPast ? '#f0f0f0' : 'inherit',
        color: isPast ? '#d3d3d3' : 'inherit',
        pointerEvents: isPast ? 'none' : 'auto',
      },
    });
  };

  const CustomEvent = ({ event, view }) => {
    return (
      <div 
      style={{
        // marginBottom: currentView=='month'? '5px' : '',
        // backgroundColor: 'red',
        minHeight:'110px'
      }}
      >
        {
          event?.is_marked_availability? 
          <div className="mb-0 pb-0 mt-0 pt-0">
          <strong style={{fontSize: '12px', color: 'red'}}>{"Marked Available"}</strong>
          </div>
          : 
          null
        }
        <strong style={{fontSize: '12px'}}>{event.title}</strong>
        {
          event?.slot_type == 'fully' && 
          (event?.is_booked?
            <div style={{ fontSize: '10px', color: 'red' }}>
            {"(Not Available)"}
            </div>
            :
          <div className={view=="month"?"py-0":"py-1"} style={{ fontSize: '10px', color: '#888' }}>
            {"(Full)"}
          </div>)
        }
        {(event.max_occupancy || event?.max_athlete) && event?.slot_type != 'fully' ? (
          <div className={view=="month"?"py-0":"py-1"} style={{ fontSize: '10px', color: '#888' }}>
            {props?.user?.user_role?.id==2?
            (event?.max_athlete == event?.athelete_booking_count?
              <p style={{color: 'red'}}>{"(Not Available)"}</p> :
            `(${(event?.max_athlete - event?.athelete_booking_count)}/` + (event?.max_athlete) + " spots remaining)"
            )
            :
            (event?.max_coaches == event?.coach_booking_count?
              // "(Not Available)" :
              <p style={{color: 'red'}}>{"(Not Available)"}</p> :
            `(${(event?.max_coaches - event?.coach_booking_count)}/` + (event?.max_coaches) + " spots remaining)"
            )  
            }
          </div>
        ):null}
        {event?.selected_amenity && currentView!='month' &&
        <p className="mb-0 pb-1" style={{fontSize: '10px'}}>{event?.selected_amenity?.amenity}</p>}
        {
        // currentView!="month"&&
        <div className="mt-0 pt-0" style={{ fontSize: '10px', color: '#888' }}>
            {
            props?.user?.user_role?.id==3?
            ("$"+ (event?.coach_fee?.toLocaleString("ja-JP") || event?.facility_fully_fee?.toLocaleString("ja-JP")))
            :
            ("$"+(event?.athlete_fee?.toLocaleString("ja-JP") || event?.facility_fully_fee?.toLocaleString("ja-JP")))
          }
        </div>}
      </div>
    );
  };


  return (
    <div className="main py-lg-5 py-4 align-items-start">
      <div 
      style={{
        width: '98%'
      }}
      // className="container"
      >
        <div className="row justify-content-center px-md-3">
          <div className="col-lg-12 col-md-12 custom-calendar">
            <h1 className="fs-24 medium pb-3">Select Available Slot
                <Link className="add-location-link text-green d-flex align-items-center" 
                onClick={(e) => { 
                  setModalIsOpen(true);
                }}>
                <i className="far fa-share-alt"></i> 
                <p className="ms-2">Share</p>
                </Link>
            </h1>

            <Calendar
            className="mb-5 event-overflow"
              localizer={localizer}
              selectable
              events={segmentedEventsWithBookingCounts || []}
              startAccessor="start"
              endAccessor="end"
              step={60} // 1 hour intervals
              timeslots={1}
              style={{ height: 600 }}
              showAllEvents={true}
              onView={handleViewChange}
              onSelectEvent={handleSelectEvent}
              components={{
                toolbar: CustomToolbar,
                dateCellWrapper: CustomDateCellWrapper,
                event: (props) => <CustomEvent {...props} view={currentView} />,
              }}
            />
            
          </div>
        </div>

        <div className="modal fade" id="coach_availability">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content border-0  px-md-3">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Booking for:</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
               {kidsList?.length > 0 && <React.Fragment>
                <h4 className="semibold pb-2">Choose Athletes</h4>
                <div className="mb-2">
                  {kidsList?.map((kid, i) => {
                    return (
                      <div
                      onClick={(e) => {
                        const item = kidsList.find(x => x.id == kid.id)
                        setKidId(kid.id)
                        setKidInfo(item)
                      }}
                      className="c-p rounded-3 bg-lightgreen p-2 d-flex align-items-center gap-2 mb-1" key={i}>
                        <input
                          type="radio"
                          name="kidgroup"
                          className="location-radio check-radio"
                          value={kid.id}
                          // onChange={(e, kid) => {
                          //   const item = kidsList.find(x => x.id == e.target.value)
                          //   setKidId(e.target.value)
                          //   setKidInfo(item)
                          // }}
                          checked={kidId == kid.id}
                        />
                        {kid.id != 'self' && <React.Fragment>
                          <img
                            src={kid.image ? kid.image : '../assets/img/edit-profile.png'}
                            width="45"
                            height="45"
                            className="rounded-50 rounded-pill"
                            alt=""
                          />
                          <div className="w-75">
                            <h6 className="semibold">{kid.first_name} {kid.last_name}</h6>
                            <h6 className="fs-11 text-lightgray regular">Age: {kid.age}yrs</h6>
                          </div>
                        </React.Fragment>}
                        {kid.id === 'self' && <React.Fragment>
                          <img
                            src={user.image ? user.image : '../assets/img/edit-profile.png'}
                            width="45"
                            height="45"
                            className="rounded-50 rounded-pill"
                            alt=""
                          />
                          <div className="w-75">
                            <h6 className="semibold">{kid.name}</h6>
                          </div>
                        </React.Fragment>}
                      </div>
                    )
                  })}
                </div>
              </React.Fragment>}
                <button
                onClick={()=>{
                  let data = {
                    kidInfo: kidInfo && kidInfo.id === 'self' ? null : kidInfo,
                    slug: params?.slug
                  };
                  if(!kidId){
                    ToastMessage?.Error('Choose athlete to proceed')
                  }
                  else{
                    $("#coach_availability").modal("hide");
                    localStorage?.setItem('slug_local', params?.slug);
                    history("/booking-summary", { state: data });
                  }
                }}
                  type="submit" 
                  className="butn w-100 rounded-pill border-0"
                >
                  Continue
                </button>
            </div>
          </div>
        </div>
      </div>

        <FacilityCoachModal
        slug={params?.slug}
        show={modalIsOpen2}
        onClose={()=>setModalIsOpen2(false)}
        />

        <ShareModal
        show={modalIsOpen}
        onClose={()=>setModalIsOpen(false)}
        slug={params.slug}
        />

      <CreateReferAvailability
      modalIsOpen={markedAvailModal}
      setModalIsOpen={setMarkedAvailModal}
      eventDetails={clickedEventDt}
      isBeforeBooking={true}
      Api={getAvailability}
      />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
//export default Header;
export default connect(mapStateToProps, {})(CheckOthersAvailability);