import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import { connect } from "react-redux";
import { formatTime } from "../../Utils/constants";
import Swal from "sweetalert2";
import ToastMessage from "../../Utils/ToastMessage";
import moment from "moment";
import Loader from "../../Components/loader";
const $ = window.jQuery;

function MyBookingUpcoming(props) {
  const [bookingList, setBookingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingInfo, setBookingInfo] = useState({});
  const [selectedItem, setSelectedItem] = useState(null)
  const [cancelled_reason, setCancelledReason] = useState('')
  const [activeTab, setActiveTab] = useState((props?.user && props?.user?.user_role && props?.user?.user_role?.name === 'Facilities') ? '1' : '2')
  const getMyBooking = async (itemUrl, viewTab) => {
    try {
      let resp = await FetchApi(itemUrl ? itemUrl + `&bookingData=${viewTab === '1' ? 'athelete' : 'my'}` : Endpoints.getBookings + `?&bookingData=${viewTab === '1' ? 'athelete' : 'my'}`);
      if (resp && resp.type === "success") {
        setBookingInfo(resp.data)
        const avaailabilityList = resp.data.data
        avaailabilityList.forEach((item) => {
          item['start'] = item.time_slot ? formatTime(item.time_slot) : ''
          item['end'] = item.time_slot_end ? formatTime(item.time_slot_end) : ''
        })
        setBookingList(avaailabilityList)
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  }
  const reSchedule = async (e, item) => {
    e.preventDefault();
    setCoachInfo(item.coach_facility);
    getAvailability(item.coach_facility.slug);
    setSelectedItem(item)
    $("#coach_availability").modal("show");
  }
  const approveBooking = async (e, item) => {
    e.preventDefault();
    try {
      let resp = await FetchApi(Endpoints.confirmBooking, { booking_id: item.id });
      if (resp && resp.type === "success") {
        ToastMessage.Success(resp.message);
        getMyBooking(null, activeTab);
      }
      if (resp && resp.status === "false") {
        ToastMessage.Error(resp.message);
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  }
  const cancelBooking = async (reschedule_) => {
    if(!cancelled_reason && !reschedule_){
      ToastMessage.Error('Please enter valid cancellation reason');
      return
    }
    try {
      setIsLoading(true);
      let resp = await FetchApi(Endpoints.cancelBooking, { booking_id: selectedItem.id, cancelled_reason: reschedule_? "Reschedule" :cancelled_reason }, 'PUT');
      if (resp && resp.type === "success") {
        setIsLoading(false);
        // if(!reschedule_){
          // setIsLoading(false);
          ToastMessage.Success(resp.message);
          getMyBooking(null, activeTab);
        // }
        $('#cancelbooking').modal('hide');
        if(reschedule_){
          $('#coach_availability').modal('hide');
          setIsLoading(false);
          history(`/view-availability/${coachInfo?.slug}`)
        }
        // getMyBooking(null, activeTab);
        // $('#cancelbooking').modal('hide');
      }
      if (resp && resp.status === "false") {
          setIsLoading(false);
        ToastMessage.Error(resp.message);
      }
    }
    catch (e) {
          setIsLoading(false);
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  }
  useEffect(() => {
    getMyBooking(null, activeTab)
    getKidsList();
  }, []);
  // reschedule realted changes
  const [location, setLocation] = useState('');
  const [time, setTime] = useState('');
  const [day, setDay] = useState('');
  const [kidId, setKidId] = useState('');
  const [kidInfo, setKidInfo] = useState(null);
  const [kidsList, setKidsList] = useState([]);
  const [user, setUserInfo] = useState(props.user)
  const [coachInfo, setCoachInfo] = useState({})
  const [availabilityList, setAvailabilityList] = useState([])
  const [times, setTimes] = useState([])
  const history = useNavigate();
  const getDayOfWeek = (date) => {
    const dayOfWeek = new Date(date).getDay();
    return isNaN(dayOfWeek) ? null :
      ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][dayOfWeek];
  }

  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
      setWindowWidth(window?.innerWidth);
      function handleResize() {
          setWindowWidth(window?.innerWidth);
      }

      window.addEventListener('resize', handleResize);

      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, [])

  const [isWithin24Hours, setIsWithin24Hours] = useState(false);

  useEffect(() => {
    const checkDateTimeWithin24Hours = (availabilityDate, timeSlot) => {
      const now = new Date();
      const availabilityDateTime = new Date(`${availabilityDate}T${timeSlot}`);

      const timeDifference = availabilityDateTime - now;
      const hoursDifference = timeDifference / (1000 * 60 * 60);

      return hoursDifference <= 24 && hoursDifference >= 0;
    };

    const result = checkDateTimeWithin24Hours(selectedItem?.availability_date, selectedItem?.time_slot);
    setIsWithin24Hours(result);
  }, [selectedItem]);

  console.log('isWithin__',isWithin24Hours)
  console.log('selectd__',selectedItem)
  const getAvailability = async (slug) => {
    try {
      let resp = await FetchApi(Endpoints.getUserAvailability, { user_slug: slug });
      if (resp && resp.type === "success") {
        let avaailabilityList = resp.data
        avaailabilityList.forEach((item) => {
          item.availability_timing.forEach((time) => {
            time['start'] = time.time_from ? formatTime(time.time_from) : ''
            time['end'] = time.time_to ? formatTime(time.time_to) : ''
            time['show'] = time.time_from ? true : false
          })
        })
        setAvailabilityList(avaailabilityList)
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  }
  const getKidsList = async () => {
    try {
      let resp = await FetchApi(Endpoints.getUserKids);
      if (resp && resp.type === "success") {
        const kidsList = resp.data
        if (kidsList.length > 0) {
          let data = kidsList[0].user_kids;
          if (data.length > 0) {
            data.push({
              id: 'self',
              name: 'Self'
            })
          }
          setKidsList(data)
        }
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  }

  const Submit = async (e) => {
    e.preventDefault();
    cancelBooking(true);
    // let bookingInfo = {
    //   coachData: coachInfo,
    //   location: location,
    //   slot: time,
    //   date: day,
    //   kidInfo: kidInfo && kidInfo.id === 'self' ? null : kidInfo,
    //   isReschedulBooking: true,
    //   bookingId: selectedItem.id
    // };
    // /* history("/booking-summary", { state: data }); */
    // const postObject = {
    //   //payment_transaction_id: data.paymentTransaction,
    //   coach_facility_id: bookingInfo.coachData.id,
    //   availability_id: bookingInfo.slot.availability_id,
    //   availability_timing_id: bookingInfo.slot.id,
    //   availability_date: bookingInfo.date.replaceAll("-", "/"),
    //   time_slot: bookingInfo.slot.time_from.slice(0, -3),
    //   time_slot_end: bookingInfo.slot.time_to.slice(0, -3),
    //   //is_used_reward_checked: props.bookingInfo.is_used_reward_checked ? '1' : '0',
    //   //tax_amount: props.bookingInfo.taxAmount,
    //   //total_amount: props.amount
    //   booking_id: bookingInfo.bookingId
    // }
    // if (bookingInfo.kidInfo && bookingInfo.kidInfo.id !== 'self') {
    //   postObject['user_kid_id'] = bookingInfo.kidInfo.id
    // }
    // try {
    //   let resp = await FetchApi(Endpoints.reschedulBooking, postObject);
    //   if (resp && resp.type === "success") {
    //     $("#coach_availability").modal("hide");
    //     getMyBooking(null, activeTab)
    //   }
    //   else {
    //     Swal.fire({
    //       text: JSON.stringify(resp.message),
    //       icon: "error",
    //     });
    //   }
    // }
    // catch (e) {
    //   // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    // }
  };


  const navigateUserHome = async (user) => {
    try {
      let resp = await FetchApi(Endpoints.userDetail, { slug: user?.slug });
      if (resp && resp.type === 'success') {
        const userInfo = resp.data;
        userInfo['viewOnly'] = true;
        if (userInfo?.user_role?.name === 'Coach') {
          history('/coach-details/' + user?.slug);
        } else if (userInfo?.user_role?.name === 'Facilities') {
          history('/facilities-details/' + user?.slug);
        } else {
          history('/user-details/' + user?.slug);
        }
      }
    } catch (e) {
      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: 'Failed to delete the post',
          icon: 'error',
        });
      }
    }
  };

  return (
    <div className="main py-lg-5 py-4 align-items-start">
      <div className="container">
        {props?.user?.user_role?.name === 'Coach' ? <React.Fragment>
          <ul className="nav nav-tabs">
          <li className="nav-item">
              <a className={`nav-link c-p ${activeTab === '2' ? 'active' : ''}`} onClick={(e) => {
                e.preventDefault();
                if (activeTab === '1') {
                  setActiveTab('2')
                  getMyBooking(null, '2');
                }
              }}>Booked by Me</a>
            </li>
          
            <li className="nav-item">
              <a className={`nav-link c-p ${activeTab === '1' ? 'active' : ''}`} onClick={(e) => {
                e.preventDefault();
                if (activeTab === '2') {
                  setActiveTab('1');
                  getMyBooking(null, '1');
                }
              }}>Booked by Athlete</a>
            </li>
           
          </ul>
        </React.Fragment> : <React.Fragment>
        </React.Fragment>}
        <h1 className={`fs-24 medium pb-2 ${props?.user?.user_role?.name === 'Coach' ? 'mt-2' : ''}`}>{props?.user?.user_role?.name === 'Coach' ? (activeTab === '1' ? 'Booked by Athlete' : 'Booked by Me') : 'My Bookings'}</h1>
        <table
        className="job-table w-100 booking-table upcoming-booking">
          <thead>
            <tr className="fs-14 text-green table-head bg-lightgreen">
              <th style={{width: windowWidth>450? '150px' : null}}>Booking ID</th>
              <th style={{width: windowWidth>450?'350px':null}}>{props?.user?.user_role?.name === 'Coach' ? (activeTab === '2' ? 'Facilities' : 'Athlete') : (props?.user?.user_role?.name === 'Facilities' ? 'Coaches/Athlete' : 'Coaches/Facilities')} </th>
              <th>Booking Date & Time</th>
              <th>Amount</th>
              <th>Status</th>
              {props?.user?.user_role?.name === 'Facilities' ?
                <th>Payouts </th>
                : ""}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {bookingList.map((item, i) => {
              return (
                <tr className="border" key={i}>
                  <td>
                    <h5 className="medium fs-13 text-darkgray">{item.id}</h5>
                  </td>
                  <td>
                    <div className="d-flex gap-2">
                      {activeTab == 2 && <img
                        src={item.coach_facility && item.coach_facility.image ? item.coach_facility.image : "../assets/img/edit-profile.png"}
                        // width="50"
                        // height="50"
                        style={{
                          width: '50px',
                          height: '50px', 
                          objectFit: 'cover',
                          borderRadius: '50%'
                        }}
                        alt=""
                        className="c-p"
                        onClick={() => {
                          navigateUserHome(item.coach_facility);
                        }}
                      />}
                      {activeTab == 1 && 
                      <img
                        src={item?.user_kids ? item?.user_kids?.image : 
                          item.user && item.user.image ? item.user.image : "../assets/img/edit-profile.png"
                        }
                        // width="50"
                        // height="50"
                        style={{
                          // width: '100%',
                          // height: '100%', 
                          width: '50px',
                          height: '50px',
                          objectFit: 'cover',
                          borderRadius: '50%'
                        }}
                        alt=""
                        className="c-p"
                        onClick={() => {
                          navigateUserHome(item.user);
                        }}
                      />
                      }
                      <div>
                        {activeTab == 2 &&
                          <React.Fragment>

                            <h5 className="semibold text-darkgray c-p"
                              onClick={() => {
                                navigateUserHome(item.coach_facility);
                              }}>{item.coach_facility.name}</h5>

                            <span className="text-878787 fs-13 medium">
                              {item.coach_facility.user_sports.name}
                            </span>
                            <h6 className="semibold">
                              Booking Location:{" "}
                              <span className="regular d-block">
                                {item.coach_facility.address}
                              </span>{" "}
                            </h6>
                          </React.Fragment>
                        }
                        {activeTab == 1 &&
                          <React.Fragment>
                            <h5 className="semibold text-darkgray c-p"
                              onClick={() => {
                                navigateUserHome(item.user);
                              }}
                            >
                              {item?.user_kids? (item?.user_kids?.first_name + " " + item?.user_kids?.last_name) : item.user.name + " "} 
                              {item.user.last_name && !item?.user_kids ? item.user.last_name : ''}
                            </h5>
                            <span className="text-878787 fs-13 medium">
                              {item.user.user_sports.name}
                            </span>
                            <h6 className="semibold">
                              Booking Location:{" "}
                              <span className="regular d-block">
                                {item.user.address}
                              </span>{" "}
                            </h6>
                          </React.Fragment>
                        }
                      </div>
                    </div>
                  </td>
                  <td>
                    <h6 className="medium pb-2  text-434343">
                      <span className="regular semibold fs-13"> Date:</span> {item.availability_date}
                    </h6>
                    <h6 className="medium text-434343">
                      <span className=" regular semibold fs-13">Time:</span>{" "}
                      {item.start} - {item.end}
                    </h6>
                  </td>
                  <td>
                    <h5 className="medium fs-13">${item.total_amount.toLocaleString("ja-JP")}</h5>
                  </td>
                  <td>

                    {
                    // moment().format("YYYY-MM-DD") <= moment(item.availability_date).format("YYYY-MM-DD") ?
                    (
                      moment(item?.availability_date).isBefore(new Date(), 'day') ||
                    // moment(item?.time_slot, "HH:mm:ss")?.isBefore(moment()) 
                    (moment(item.availability_date).isSame(moment(), 'day') && moment(item?.time_slot_end, "HH:mm:ss")?.isBefore(moment())) )
                    && item.status!="cancelled"
                    ?
                      // moment().format("h:m A") <= item.end ?
                      //   <h5 className="semibold fs-13 text-orange capitalize">
                      //     {item.status}
                      //   </h5>
                      //   :
                      //   <h5 className="semibold fs-13 text-orange capitalize">Expire</h5>
                      // :
                      <h5 className="semibold fs-13 text-orange capitalize">Expired</h5>
                      : 
                      <h5 className="semibold fs-13 text-orange capitalize">
                           {item.status}
                      </h5>
                    }
                  </td>

                  {props?.user?.user_role?.name === 'Facilities' ?
                    item.status == 'completed' ?
                      <td>
                        <h5 className="semibold fs-13 text-green capitalize">
                           Paid
                        </h5>
                        
                      </td>
                      :
                      <td>
                        <h5 className="semibold fs-13 text-orange capitalize">
                          Pending
                        </h5>
                        
                      </td>
                    : ""}
                  
                  {
                  // moment().format("YYYY-MM-DD") <= moment(item.availability_date).format("YYYY-MM-DD") ?
                  moment(item?.availability_date).isBefore(new Date(), 'day') ||
                    // (moment(item.availability_date).isSame(moment(), 'day') && moment().format("h:m A")<= item.time_slot_end)
                    (moment(item.availability_date).isSame(moment(), 'day') && moment(item?.time_slot_end, "HH:mm:ss")?.isBefore(moment()))
                    ?
                    <td></td>
                    :
                      <td>

                        {((props?.user?.user_role?.name === 'Coach' && activeTab !== '1') || (props.user?.user_role?.name !== 'Coach' && activeTab !== '1')) && item.status !== 'cancelled' && <Link className="fs-13 semibold text-blue me-4" 
                        onClick={(e) => {
                          reSchedule(e, item)
                        }}>Reschedule</Link>}
                        {((
                          props?.user?.user_role?.name === 'Coach' && 
                          activeTab === '1' && 
                          item.status === 'scheduled'
                        )) && 
                          <Link className="fs-13 semibold text-blue me-4" onClick={(e) => {
                          approveBooking(e, item)
                        }}>Confirm</Link>}
                        {item.status !== 'cancelled' &&
                          <Link className="fs-13 semibold  text-danger" onClick={(e) => {
                            e.preventDefault();
                            setSelectedItem(item)
                            setCancelledReason('')
                            $('#cancelbooking').modal('show')
                          }}>Cancel</Link>
                        }
                      </td>
                      // :
                      // <td></td>

                    // :
                    // <td>

                    // </td>
                  }
                </tr>
              )
            })}
            {bookingList.length == 0 &&
              <tr>
                <td className="border">
                  No bookings Available
                </td>
              </tr>
            }
          </tbody>
        </table>
        {bookingList.length > 0 && <div className="paginations text-start py-3">
          <span className="me-2">Page 1 of {Math.ceil(bookingInfo.total / bookingInfo.per_page)}</span>
          {bookingInfo.links.map((item, i) => {
            return (item.url ? (<React.Fragment key={i}>
              <Link className={item.active ? 'page-active' : ''} onClick={(e) => {
                e.preventDefault();
                getMyBooking(item.url, activeTab)
              }}><span dangerouslySetInnerHTML={{ __html: item.label }}></span></Link>
            </React.Fragment>) : null)
          })}
        </div>}
      </div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="cancelbooking"
        tabIndex="-1"
        aria-labelledby="sharepostmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Cancel Booking</h5>
              <button
                type="button"
                className="modal-close bg-transparent border-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="../assets/img/icon/close-circle.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              {
              (props?.user?.user_role?.name === 'User' || 
              (props?.user?.user_role?.name === 'Coach' && activeTab === '2')) && 
              isWithin24Hours && selectedItem?.availability?.is_want_cancellation_charge==1 &&
              <div>
                <div className="d-flex align-items-center">
                  <p style={{fontSize: '12px'}}>
                    Cancellation Charge: ${
                    // selectedItem?.availability?.cancellation_charge.toLocaleString("ja-JP")
                    selectedItem?.cancellation_charge?.toLocaleString("ja-JP")
                    }
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <p style={{fontSize: '12px'}}>
                    Refund Amount: ${(selectedItem.total_amount - selectedItem?.cancellation_charge).toLocaleString("ja-JP")}
                  </p>
                </div>
              </div>
              }
              <form>
                <div className="mb-3">
                  <label htmlFor="message-text" className="col-form-label">Message:</label>
                  <textarea className="form-control" id="message-text" value={cancelled_reason} onChange={(e) => setCancelledReason(e.target.value)}></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
              <button type="button" className="btn btn-primary" onClick={(e) => { cancelBooking() }}>Yes</button></div>
          </div>
        </div>
      </div>
      {/* <!-- End Modal --> */}
      {/* <!-- Modal --> */}
      <div className="modal fade" id="coach_availability">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content border-0  px-md-3">
          
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Reschedule</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form
                id=""
                onSubmit={Submit}
                action=""
                className="site-form"
              >
                {
                (props?.user?.user_role?.name === 'User' || 
                (props?.user?.user_role?.name === 'Coach' && activeTab === '2')) && 
                isWithin24Hours && selectedItem?.availability?.is_want_cancellation_charge==1 ?
                <div>
                <p>
                  This booking will be canceled, and you will be redirected to the {coachInfo?.role_id==3? "coach's" : "facility's"} calendar to book a new slot based on your availability. 
                  </p>
                  
                  <p>If you confirm and proceed, ${(selectedItem.total_amount - selectedItem?.cancellation_charge).toLocaleString("ja-JP")} will be refunded.</p>
                  <div className="d-flex align-items-center">
                    <p style={{fontSize: '12px'}}>
                      Cancellation Charge: ${
                      // selectedItem?.availability?.cancellation_charge.toLocaleString("ja-JP")
                      selectedItem?.cancellation_charge?.toLocaleString("ja-JP")
                      }
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <p style={{fontSize: '12px'}}>
                      Refund Amount: ${(selectedItem.total_amount - selectedItem?.cancellation_charge).toLocaleString("ja-JP")}
                    </p>
                  </div>
                </div>
                :
                <div style={{
                  fontWeight: 'bold'
                }}>
                  <p>
                  This booking will be canceled, and you will be redirected to the {coachInfo?.role_id==3? "coach's" : "facility's"} calendar to book a new slot based on your availability. 
                  </p>
                  
                  <p>If you confirm and proceed, the full amount of this booking will be refunded.</p>
                </div>
                }
                <button
                  type="submit" 
                  // disabled={(!time || !location) ? true : false}
                  className="butn w-100 rounded-pill border-0 mt-4"
                >
                  {isLoading? <Loader isButton={true}/> : "Proceed"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Modal --> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
//export default Header;
export default connect(mapStateToProps, {})(MyBookingUpcoming);
