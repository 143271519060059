import React, { useEffect, useState } from 'react'
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { Button, Modal } from 'react-bootstrap';

export default function JobApplication() {
    const [List, setList] = useState([]);
    const [Pagination, setPagination] = useState({});
    const params = useParams();
    const history = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState("");

    const handleReadMore = (message) => {
        setModalContent(message);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setModalContent("");
    };
    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        let data = {
            job_id:params?.id
        }
        try {
            let resp = await CommonAPi(Endpoints.GetJobApplication, data);
            if (resp && resp.status === "success") {
                setList(resp.data?.data);
                setPagination(resp.data);
            }
        } catch (e) {
            if (e && e.response && e.response.message) {
                Swal.fire({
                    text: e.response.message,
                    icon: "error",
                });
            }
        }
    };
    return (
        <div className="wrapper">
            <div className="main py-5">
                <div className="container">
                    <h1 className="fs-24 medium pb-2">
                        Manage Job Application
                    </h1>

                    <table className="job-table w-100">
                        <thead>
                            <tr className="fs-14 text-green table-head bg-lightgreen">
                                <th>Applicants Name</th>
                                <th>Applicants Email</th>
                                <th>Applicants Number</th>
                                <th>Applicants Resume</th>
                                <th>Message</th>

                                {/* <th>Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {List?.map((item, index) => {
                                return (
                                    <tr key={index} className="border">
                                        <td>
                                            <h5 
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={()=>{
                                                history('/user-details/' + item?.user?.slug);
                                            }}
                                            className="medium pb-2">{item?.user?.name} {item?.user?.last_name}</h5>

                                        </td>

                                        <td>
                                            <h6 className="medium pb-2 ">
                                                {item?.user?.email}
                                            </h6>


                                        </td>

                                        <td>
                                            <h6 className="medium">
                                                {item?.user?.phone}
                                            </h6>
                                        </td>

                                        <td>
                                            <Link to={item?.file_url} target={"_blank"} className="text-primary">

                                                <h6 className="medium">
                                                   {item?.file_name}
                                                </h6>
                                            </Link>

                                        </td>
                                        <td>
                                            {/* <h6 className="medium">
                                                {item?.message ? item?.message : "-"}

                                            </h6> */}
                                              <h6
                                                className="medium"
                                                style={{
                                                    overflow: "hidden",
                                                    display: "-webkit-box",
                                                    WebkitBoxOrient: "vertical",
                                                    WebkitLineClamp: 3,
                                                }}
                                            >
                                                {item?.message ? item?.message : "-"}
                                            </h6>
                                            {item?.message?.length > 150 && (
                                                <div
                                                    className="p-0 text-decoration-none text-green c-p"
                                                    onClick={() => handleReadMore(item.message)}
                                                >
                                                    Read More
                                                </div>
                                            )}

                                        </td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {Pagination.length > 0 && (
                        <div className="paginations text-start py-3">
                            <span className="me-2">
                                Page 1 of{" "}
                                {Math.ceil(Pagination.total / Pagination.per_page)}
                            </span>
                            {Pagination.links.map((item, i) => {
                                return item.url ? (
                                    <React.Fragment key={i}>
                                        <Link
                                            className={item.active ? "page-active" : ""}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                getData(item.url);
                                            }}
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{ __html: item.label }}
                                            ></span>
                                        </Link>
                                    </React.Fragment>
                                ) : null;
                            })}
                        </div>
                    )}

                </div>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalContent}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
