import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { ValidateList, ValidationTypes } from "../../Utils/ValidationHelper";
import { useEffect } from "react";
import Swal from "sweetalert2";
const $ = window.jQuery;
function ManageKids() {
  const history = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [age, setAge] = useState('');
  const [kidId, setKidId] = useState('');
  const [kidsList, setKidsList] = useState([])
  const [image, setImage] = useState('');
  const [imageData, setImageData] = useState('');
  const [selectedIndex, setSelectedItem] = useState(null);
  const clearValue = (event) => {
    setAge('');
    setImage('');
    setImageData('');
    setFirstName('');
    setLastName('');
    setKidId('')
  }
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageData(event.target.files[0]);
    }
  };
  const getKidsList = async () => {
    try {
      let resp = await FetchApi(Endpoints.getUserKids);
      if (resp && resp.type === "success") {
        const kidsList = resp.data
        if (kidsList.length > 0) {
          const data = kidsList[0].user_kids;
          setKidsList(data)
        }
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  }
  const showEditForm = async (item) => {
    setAge(item.age);
    setImage(item.image);
    setFirstName(item.first_name);
    setLastName(item.last_name);
    setKidId(item.id)
    $("#newkid").modal("show");
  }
  const deletePopup = (i) => {
    setSelectedItem(i)
    $('#deleteKid').modal('show');
  }
  const deleteKids = async () => {
    const item = kidsList[selectedIndex]
    try {
      const postData = { id: item.id }
      let resp = await FetchApi(Endpoints.deleteUserKids, postData, 'DELETE');
      if (resp && resp.status === "success") {
        $('#deleteKid').modal('hide');
        ToastMessage.Success(resp.message);
        let newKidsList = [...kidsList].filter(i => i.id !== item.id)
        setKidsList(newKidsList)
      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is not exist",
          icon: "error",
        });
      }
    } catch (e) {
      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  const register = async (e) => {
    e.preventDefault();
    let validate_arr = [
      [firstName, ValidationTypes.Empty, "Please enter first name"],
      [lastName, ValidationTypes.Empty, "Please enter last name"],
      [age, ValidationTypes.Empty, "Please enter a valid aage"],
      [imageData, ValidationTypes.Empty, "Please select image"],
    ];
    let validate = await ValidateList(validate_arr);
    if (!validate) {
      return;
    }
    let dat = new FormData();
    dat.append("age", age);
    dat.append("last_name", lastName);
    dat.append("first_name", firstName);
    dat.append("image", imageData);
    if (kidId) {
      dat.append("id", kidId);
    }
    try {
      let resp = await FetchApi(Endpoints.saveUserKids, dat);
      if (resp && resp.status === "success") {
        $("#newkid").modal("hide");
        ToastMessage.Success(resp.message);
        getKidsList();
      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is not exist",
          icon: "error",
        });
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  useEffect(() => {
    getKidsList()
  }, []);
  return (
    <div className="main py-5 align-items-start">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-10 col-sm-11">
            <div className="d-flex align-items-center gap-2 mb-3">
              <h1 className="fs-24 medium">Manage Athletes</h1>
              <a data-bs-toggle="modal" data-bs-target="#newkid" onClick={(e) => clearValue(e)} className="butn gradient-butn c-p">Add New Athletes</a>
            </div>
            {kidsList.map((item, i) => {
              return (
                <div className="rounded-3 border p-2 d-flex w-100 align-items-center gap-2 mb-2" key={i}>
                  <div className="d-flex w-100 align-items-center gap-2 ">
                    <img src={item.image ? item.image : 'assets/img/edit-profile.png'} width="45" height="45" className="rounded-50" alt="" />
                    <div className="w-75">
                      <h6 className="semibold">{item.first_name} {item.last_name}</h6>
                      <h6 className="fs-11 text-lightgray regular">Age: {item.age}yrs</h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <a onClick={(e) => { deletePopup(i) }} className="job-action-btn"><img src="assets/img/icon/trash.svg" className=" icon-red" /></a>
                    <a onClick={(e) => { showEditForm(item) }} className="job-action-btn"><img src="assets/img/icon/edit.svg" className=" icon-red" /></a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="modal fade" id="newkid">
        <div className="modal-dialog ">
          <div className="modal-content border-0  px-3">
            <div className="modal-header border-0 p-2">
              <button type="button" onClick={(e) => clearValue(e)} className="modal-close bg-transparent border-0" data-bs-dismiss="modal" aria-label="Close">
                <img src="assets/img/icon/close-circle.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <h3 className="text-darkgray pb-4 text-center">Add Athletes</h3>
              <form className="site-form">
                <div className="mb-4 text-center">
                  <div className="position-relative profile-thumb overflow-hidden rounded-circle mx-auto mb-1">
                    <input type="file" id="profile-field" onChange={onImageChange} className="d-none" />
                    <img src={image || "assets/img/edit-profile.png"} className="profile-pic rounded-circle" />
                    <label htmlFor="profile-field" className="profile-upload d-flex align-items-center justify-content-center start-0 bottom-0 position-absolute">
                      <img src="assets/img/icon/cover-picture.svg" alt="" />
                    </label>
                  </div>
                  <label htmlFor="" className="pb-1">Profile Picture</label>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-field mb-3">
                      <label htmlFor="" className="pb-1">First Name</label>
                      <input type="text" className="text-gray" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Enter your first name" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-field mb-3">
                      <label htmlFor="" className="pb-1">Last Name</label>
                      <input type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} className="text-gray" placeholder="Enter your Last name" />
                    </div>
                  </div>
                </div>
                <div className="form-field mb-3">
                  <label htmlFor="" className="pb-1">Age</label>
                  <input type="number" onChange={(e) => setAge(e.target.value)} value={age} className="text-gray" placeholder="Age" />
                </div>
                <div className="mt-3 text-center group-butn">
                  <a data-bs-dismiss="modal" aria-label="Close" onClick={(e) => clearValue(e)} className="butn gray-butn c-p">Cancel</a>
                  <a onClick={register} className="butn ms-2 c-p">Save</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div className="modal fade"
        id="deleteKid"
        tabIndex="-1"
        aria-labelledby="sharepostmodal"
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Athletes</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Are you sure ? Do you want to delete this Athletes.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-danger" onClick={(e) => { deleteKids() }}>Delete</button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- End Modal --> */}
    </div>
  );
}

export default ManageKids;
