import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { CommonAPi } from '../API/CommonApi';
import { Endpoints } from '../API/Endpoints';
import ToastMessage from '../Utils/ToastMessage';
import { formatAMPM, monthNames, uuidv4 } from '../Utils/constants';
import { FetchApi } from '../API/FetchApi';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../Components/loader';
const $ = window.jQuery;

export function Posts(props) {
	const history = useNavigate();
	const [newPostComment, setNewPostComment] = useState('');
	const [newSharePostComment, setNewSharePostComment] = useState('');
	const inputElement = useRef(null);
	const [postFiles, setPostFiles] = useState([]);
	const [postList, setPostList] = useState([]);
	const [selectedPost, setSelectedPost] = useState(null);
	const [postImages, setPostImages] = useState([]);
	const [postVideos, setPostVideos] = useState([]);
	const [oldPostComment, setOldPostComment] = useState('');
	const [oldPostImages, setOldPostImages] = useState([]);
	const [oldPostVideos, setOldPostVideos] = useState([]);
	const [postInfo, setPostInfo] = useState({ total: 10 });
	const [showMore, setShowMore] = useState(false);
	const [loading, setLoading] = useState(false);
	const [commentLoading, setCommentLoading] = useState(false);

	const deletePostFile = async (item, itemList) => {
		try {
			const postData = { id: item.id };
			FetchApi(Endpoints.deletePostFile, postData).then((resp) => {
				if (resp && resp.status === 'success') {
					// let postItems = [...postList];
					// setPostList(postItems.filter((i) => i.id != item.id));
				}
			});
		} catch (e) {
			// if (e && e.response && e.response.message && e.response.message.email) {
			// 	Swal.fire({
			// 		text: 'Failed to delete the post',
			// 		icon: 'error',
			// 	});
			// }
		}
	};

	const onPictureChange = (event) => {
		let postImageUrlList = [...postImages];
		let postVideoUrlList = [...postVideos];
		let files = [...postFiles];
		let videoLoaded = 0;
		if (event.target.files && event.target.files.length) {
			for (let i = 0; i < event.target.files.length; i++) {
				const file = event.target.files[i];
				const id = uuidv4();
				if (file.type.includes('image')) {
					const url = URL.createObjectURL(file);
					postImageUrlList.push({ file_name: url, id: id });
				} else {
					/* const fileReader = new FileReader();
					fileReader.onloadend = () => {
						postVideoUrlList.push({ file_name: fileReader.result, id: id })
						videoLoaded = videoLoaded - 1;
						if (videoLoaded === 0) {
							setPostImages(postImageUrlList);
							setPostVideos(postVideoUrlList);
							setPostFiles(files);
							setTimeout(() => {
								$(".play-video").click(function (e) {
									var a = e.currentTarget.id.split('_');
									var video_id = `video_${a[1]}`
									$(`#${video_id}`).trigger('play').attr('controls', '');
									$(this).fadeOut();
								});
							})
						}
					};                    
					fileReader.readAsDataURL(file); */
					const url = URL.createObjectURL(file);
					postVideoUrlList.push({ file_name: url, id: id, file: file, isNew: true });
					videoLoaded = videoLoaded + 1;
				}
				files.push({
					id: id,
					file: file,
				});
			}
		}
		setPostImages(postImageUrlList);
		setPostVideos(postVideoUrlList);
		setPostFiles(files);
		setTimeout(() => {
			$('.play-video').click(function (e) {
				var a = e.currentTarget.id.split('_');
				var video_id = `video_${a[1]}`;
				$(`#${video_id}`).trigger('play').attr('controls', '');
				$(this).fadeOut();
			});
		});
	};
	const removeFile = (item) => {
		let images = [...postImages];
		let videos = [...postVideos];
		let files = [...postFiles];
		setPostImages(images.filter((i) => i.id != item.id));
		setPostVideos(videos.filter((i) => i.id != item.id));
		setPostFiles(files.filter((i) => i.id != item.id));
		document.getElementById('d-file').value = '';
	};
	const resetValues = () => {
		setNewPostComment('');
		setSelectedPost(null);
		setPostFiles([]);
		setPostImages([]);
		setPostVideos([]);
		setOldPostComment('');
		setNewSharePostComment('');
		setOldPostImages([]);
		setOldPostVideos([]);
	};
	const onSharePostSubmit = async (e) => {
		e.preventDefault();
		try {
			let postObj = { post_id: selectedPost?.shared_post?.shared_post_id ? selectedPost?.shared_post?.shared_post_id : selectedPost.id };
			if (newSharePostComment) {
				postObj['name'] = newSharePostComment;
			}
			let resp = await CommonAPi(Endpoints.sharedPost, postObj);
			if (resp && resp.status === 'success') {
				ToastMessage.Success(resp.message);
				$('#shareNewPost').modal('hide');
				resetValues();
				setNewSharePostComment("");
				getPostList();
			}
			if (resp && resp.status === 'false') {
				ToastMessage.Error(resp.message);
			}
		} catch (e) {
			console.log('registerErr__', JSON.stringify(e.response, null, 4));

			if (e && e.response && e.response.message && e.response.message.email) {
				Swal.fire({
					text: 'This email is already used',
					icon: 'error',
				});
			}
		}
	};
	const onPostSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		if (newPostComment) {
			formData.append('name', newPostComment);
		}
		for (const image of postFiles) {
			formData.append('files[]', image.file);
		}
		if (selectedPost) {
			formData.append('id', selectedPost.id);
		}
		try {
			setLoading(true);
			let resp = await CommonAPi(Endpoints.createPost, formData);
			if (resp && resp.status === 'success') {
				ToastMessage.Success(resp.message);
				$('#createpost').modal('hide');
				resetValues();
				getPostList();
			}
			if (resp && resp.status === 'false') {
				ToastMessage.Error(resp.message);
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
			console.log('registerErr__', JSON.stringify(e.response, null, 4));

			if (e && e.response && e.response.message && e.response.message.email) {
				Swal.fire({
					text: 'This email is already used',
					icon: 'error',
				});
			}
		}
	};
	const addLike = async (post, postIndex) => {
		try {
			let resp = await CommonAPi(Endpoints.likePost, { post_id: post.id });
			if (resp && (resp.status === 'success' || resp.status === 'false')) {
				//getPostList();
				let newPostList = [...postList];
				let post = newPostList[postIndex];
				post['user_post_likes_count'] = post.isLogedInUserLikePost
					? post.user_post_likes_count - 1
					: post.user_post_likes_count + 1;
				post['isLogedInUserLikePost'] = !post.isLogedInUserLikePost;
				newPostList[postIndex] = post;
				setPostList(newPostList);
			}
			if (resp && resp.message && resp.message.email) {
				Swal.fire({
					text: 'This email is already used',
					icon: 'error',
				});
			}
		} catch (e) {
			console.log('registerErr__', JSON.stringify(e.response, null, 4));
			if (e && e.response && e.response.message && e.response.message.email) {
				Swal.fire({
					text: 'This email is already used',
					icon: 'error',
				});
			}
		}
	};
	const onPostCommentClick = async (post) => {
		setSelectedPost(post);
		$('#commentModal').modal('show');
	};
	const onPostCommentSubmit = async (e) => {
		e.preventDefault();
		if(newPostComment?.length<2){
			ToastMessage.Error("Comment is required");
			return
		}
		const formData = new FormData();
		if (newPostComment) {
			formData.append('comment', newPostComment);
		}
		formData.append('post_id', selectedPost.id);
		for (const image of postFiles) {
			formData.append('files[]', image.file);
		}
		try {
			setCommentLoading(true);
			let resp = await CommonAPi(Endpoints.createPostComment, formData);
			if (resp && resp.status === 'success') {
				ToastMessage.Success(resp.message);
				$('#commentModal').modal('hide');
				resetValues();
				getPostList();
			}
			if (resp && resp.message && resp.message.email) {
				Swal.fire({
					text: 'This email is already used',
					icon: 'error',
				});
			}
			if (resp && resp.status === 'false') {
				ToastMessage.Error(resp.message);
			}
			setCommentLoading(false);
		} catch (e) {
			setCommentLoading(false);
			console.log('registerErr__', JSON.stringify(e.response, null, 4));

			if (e && e.response && e.response.message && e.response.message.email) {
				Swal.fire({
					text: 'This email is already used',
					icon: 'error',
				});
			}
		}
	};
	const loadMoreData = async (e) => {
		if (postInfo.next_page_url) {
			getPostList(`${Endpoints.getPosts}?page=${postInfo.current_page + 1}`);
		}
	};
	const getPostList = async (url) => {
		try {
			let postparams = {};
			if (props.pageFrom === 'feed') {
				postparams['data_for'] = 'all';
			} else if (props.pageFrom === 'view') {
				postparams['data_for'] = 'single';
				postparams['user_id'] = props.viewUser.id ? props.viewUser.id : props.id;
			}
			// console.log("shubham_______", props.viewUser)
			let resp = await FetchApi(url ? url : Endpoints.getPosts, postparams);
			if (resp && resp.type === 'success') {
				setPostInfo(resp.data);
				const data = resp.data.data;
				data.forEach((item) => {
					const created_at = new Date(item.created_at);
					item['created_date'] = `${created_at.getDate()} ${monthNames[created_at.getMonth()]
						} ${created_at.getFullYear()}`;
					item['time'] = formatAMPM(created_at);
					item['videos'] = item.user_post_file.filter(
						(x) => x.file_type === 'video'
					);
					item['images'] = item.user_post_file.filter(
						(x) => x.file_type !== 'video'
					);
					if (item.post_comments) {
						item.post_comments.forEach((comment) => {
							const comment_created_at = new Date(comment.created_at);
							comment['created_date'] = `${comment_created_at.getDate()} ${monthNames[comment_created_at.getMonth()]
								} ${comment_created_at.getFullYear()}`;
							comment['time'] = formatAMPM(comment_created_at);
							//comment['hasVideo'] = comment.post_comment_file.find(x => x.file_type === 'video')
						});
					}
					if (item.shared_post && item.shared_post.shared_user_post) {
						let sharedPost = item.shared_post.shared_user_post;
						const shared_post_created_at = new Date(sharedPost.created_at);
						sharedPost['created_date'] = `${shared_post_created_at.getDate()} ${monthNames[shared_post_created_at.getMonth()]
							} ${shared_post_created_at.getFullYear()}`;
						sharedPost['time'] = formatAMPM(shared_post_created_at);
						sharedPost['videos'] = sharedPost.user_post_file.filter(
							(x) => x.file_type === 'video'
						);
						sharedPost['images'] = sharedPost.user_post_file.filter(
							(x) => x.file_type !== 'video'
						);
						item.shared_post.shared_user_post = sharedPost;
					}
				});
				if (url) {
					setPostList([...postList, ...data]);
				} else {
					setPostList(data);
					setTimeout(() => {
						$('.play-video').click(function (e) {
							var a = e.currentTarget.id.split('_');
							var video_id = `video_${a[1]}`;
							$(`#${video_id}`).trigger('play').attr('controls', '');
							$(this).fadeOut();
						});
					});
				}
			}
		} catch (e) {
			console.log(e);
			console.log('PostList', JSON.stringify(e, null, 4));
		}
	};
	const deletePost = async (item, itemList) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
		}).then((result) => {
			if (result.isConfirmed) {
				try {
					const postData = { post_id: item.id };
					FetchApi(Endpoints.deletePost, postData).then((resp) => {
						if (resp && resp.status === 'success') {
							let postItems = [...postList];
							setPostList(postItems.filter((i) => i.id != item.id));
						}
						if (resp && resp.message && resp.message.email) {
							Swal.fire({
								text: 'Failed to delete the post',
								icon: 'error',
							});
						}
					});
				} catch (e) {
					if (e && e.response && e.response.message && e.response.message.email) {
						Swal.fire({
							text: 'Failed to delete the post',
							icon: 'error',
						});
					}
				}
			}
		});
	};
	const navigateUserHome = async (user) => {
		try {
			let resp = await FetchApi(Endpoints.userDetail, { slug: user?.slug });
			if (resp && resp.type === 'success') {
				const userInfo = resp.data;
				userInfo['viewOnly'] = true;
				if (userInfo.user_role.name === 'Coach') {
					history('/coach-details/' + user?.slug);
				} else if (userInfo.user_role.name === 'Facilities') {
					history('/facilities-details/' + user?.slug);
				} else {
					history('/user-details/' + user?.slug);
				}
			}
		} catch (e) {
			if (e && e.response && e.response.message && e.response.message.email) {
				Swal.fire({
					text: 'Failed to delete the post',
					icon: 'error',
				});
			}
		}
	};
	useEffect(() => {
		getPostList();
		var exampleModal = document.getElementById('createpost');
		exampleModal.addEventListener('show.bs.modal', (event) => {
			if (inputElement.current) {
				setTimeout(() => {
					inputElement.current.focus();
					$('#addEditCommentBox').focus();
				});
			}
		});
	}, []);



	return (
		<React.Fragment>
			{props.pageFrom === 'feed' && (
				<div className='search-box bg-white p-2 rounded-8 border'>
					<div className='d-flex align-items-center w-100 px-1 py-2 gap-2'>
						<div>
						<div
						style={{
							height: '40px',
							width: '40px'
						}}
						className='user-icon'>
							<img
								src={
									props?.user?.image ? props?.user?.image : '../assets/img/edit-profile.png'
								}
								style={{
									width: '100%',
									height: '100%', 
									objectFit: 'cover',
									borderRadius: '100%'
								}}
								// width='40'
								// height='40'
								alt=''
								// className='rounded-circle w-40'
							/>
						</div>
						</div>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								$('#createpost').modal('show');
								$('#addEditCommentBox').focus();
							}}
							data-bs-toggle='modal'
							data-bs-target='#createpost'
							className='search-post w-100 rounded-pill  bg-lightgreen d-flex align-items-center justify-content-between px-3 py-2'
						>
							<input
								type='text'
								className='fs-13 border-0 bg-transparent text-green'
								placeholder={`What’s on your mind, ${props?.user?.name} ${props.user?.last_name ? props.user?.last_name : ''
									}?`}
							/>
							<span className='d-flex'>
								<span className='me-3'>
									<input type='file' id='feed-image-d' className='d-none' />
									<label htmlFor='feed-image'>
										<img src='../assets/img/icon/camera.svg' alt='' />
									</label>
								</span>
								<Link
									className='send'
									onClick={(e) => {
										e.preventDefault();
										$('#createpost').modal('show');
										$('#addEditCommentBox').focus();
									}}
								>
									<img src='../assets/img/icon/send.svg' alt='' />
								</Link>
							</span>
						</form>
					</div>
				</div>
			)}
			{postList.length > 0 && (
				<h1 className='fs-24 py-3 my-md-2'>
					{props.pageFrom === 'view'
						? `${props.viewUser.name}'s Post `
						: props.pageFrom === 'feed'
							? 'Posts based on your location'
							: 'Your Post'}
				</h1>
			)}
			<InfiniteScroll
				dataLength={postInfo.total}
				next={loadMoreData}
				hasMore={
					props.pageFrom === 'feed' && postInfo.current_page !== postInfo.last_page
				} // Replace with a condition based on your data source
				loader={''}
				endMessage={''}
			>
				<ul className='timeline-feed feed-list'>
					{postList.map((post, pi) => {
						return (
							<li className='feed-item bg-white mb-3 border rounded-8' key={pi}>
								<div
									className='c-p'
									onClick={(e) => {
										e.preventDefault();
										navigateUserHome(post.user);
									}}
								>
									<div className='d-flex align-items-start feed-top mb-2'>
										<div>
										<div 
										style={{
											width: '48px',
											height: '48px'
										}}
										// className='feed-user-image'
										>
											<img
												src={
													post.user?.image ? post.user?.image : '../assets/img/edit-profile.png'
												}
												alt=''
												style={{
													width: '100%',
													height: '100%', 
													objectFit: 'cover',
													borderRadius: '100%'
												}}
												// width='48'
												// height='48'
												// className='rounded-circle w-40'
											/>
										</div>
										</div>
										<div className='ps-2 pt-1'>
											<h6 className='semibold text-black fs-15'>{post.user?.name}</h6>
											<span className='fs-13 text-lightgray'>
												{post.user?.user_role?.id == 4 ? 
												post.user?.facility_selected_amenity?.map((item, index)=>{
													return(
													item?.amenity+(index!=post.user?.facility_selected_amenity?.length-1? ", ": '')
													)
												})
												:
												post.user?.user_role?.id == 3 ? 
												post?.user?.coach_detail?.category?.name? post?.user?.coach_detail?.category?.name : ""
												// post.user?.user_speciality?.map((item, index)=>{
												// 	return(
												// 	item?.speciality+(index!=post.user?.user_speciality?.length-1? ", ": '')
												// 	)
												// })
												:
												post.user?.user_sports ? post.user?.user_sports.name : ""}
												{/* {post.user?.user_sports?.name}  */}
												{" "} | {post.created_date} | {post.time}
											</span>
										</div>
										{
										props.pageFrom !== 'feed' &&
											post.shared_post_count === 0 &&
											props.pageFrom !== 'view' && 
											(
												<div className='ms-auto'>
													<a
														className='c-p'
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															setSelectedPost(post);
															setNewPostComment(post.name);
															setPostImages(post.images);
															setPostVideos(post.videos);
															setOldPostComment(post.name);
															setOldPostImages(post.images);
															setOldPostVideos(post.videos);
															$('#addEditCommentBox').focus();
															$('#createpost').modal('show');
														}}
													>
														<img src='../assets/img/icon/edit.svg' alt='' />
													</a>
													<a
														className='ms-2 c-p'
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															deletePost(post, postList);
														}}
													>
														<img src='../assets/img/icon/trash.svg' alt='' />
													</a>
												</div>
											)}
									</div>
								</div>
								<div className='feed-desc pb-2'>
									<p className='m-0 text-696969'>
										{post.name && (
											<React.Fragment>
												{post.showFull ? post.name : post.name.slice(0, 140)}{' '}
												{post.name.length > 140 && (
													<a
														onClick={(e) => {
															e.preventDefault();
															let newPost = { ...post };
															newPost['showFull'] = !post.showFull;
															let newPostList = [...postList];
															newPostList[pi] = newPost;
															setPostList(newPostList);
														}}
														className='text-green c-p'
													>
														{post.showFull ? 'Read Less' : 'Read More'}
													</a>
												)}
											</React.Fragment>
										)}
									</p>
								</div>
								{post.images.length > 0 && (
									<div
										className={`${post.images.length === 1 ? 'feed-image' : ''} ${post.images.length === 2 ? 'feed-image feed-col2 feed-multiple' : ''
											} ${post.images.length === 3 ? 'feed-gallery feed-col3 feed-multiple' : ''} ${post.images.length === 4 ? 'feed-gallery feed-col4 feed-multiple' : ''
											} ${post.images.length > 4 ? 'feed-gallery feed-multiple' : ''} mb-2`}
									>
										{post.images.map((file, ii) => {
											return (
												<React.Fragment key={ii}>
													{post.images.length > 4 ? (
														ii > 3 ? (
															<a href={file.file_name} key={ii} className='d-none' data-fancybox={'feed_userid_' + pi}>
																<img src={file.file_name} alt='' className='w-100 h-100' />
															</a>
														) : (
															<a href={file.file_name} data-fancybox={'feed_userid_' + pi}

															>
																<img src={file.file_name} alt='' className='w-100 h-100' />
																{ii === 3 && (
																	<span className='feed-more-gal'>
																		{post.images.length - 4}+
																	</span>
																)}
															</a>
														)
													) : (
														<a href={file.file_name} data-fancybox={'feed_userid_' + pi}>
															<img src={file.file_name} alt='' className='w-100 h-100' />
														</a>
													)}
												</React.Fragment>
											);
										})}
										{/* {post.images.length > 4 && (
											<div className='d-none'>
												{post.images.map((file, ii) => {
													return (
														<a href={file.file_name} key={ii} data-fancybox={'feed_userid_'+pi}
															
														>
															<img src={file.file_name} alt='' className='w-100 h-100' />
														</a>
													);
												})}
											</div>
										)} */}
									</div>
								)}
								{post.videos.length > 0 && (
									<React.Fragment>
										{post.videos.map((file, ii) => {
											return (
												<React.Fragment key={ii}>
													<div className='feed-video position-relative'>
														<video width='100%' className='d-block' id={`video_${file.id}`}>
															<source src={file.file_name} type='video/mp4' />
														</video>
														<a
															onClick={(e) => e.preventDefault()}
															id={`a_${file.id}`}
															className='play-video c-p position-absolute w-100 h-100 align-items-center justify-content-center top-0'
														>
															<img src='../assets/img/icon/video-play.svg' alt='' />
														</a>
													</div>
												</React.Fragment>
											);
										})}
									</React.Fragment>
								)}
								{post.shared_post && post.shared_post.shared_user_post && (
									<div className="border p-2">
										<React.Fragment>
											{post.shared_post.shared_user_post.images.length > 0 && (

												<div
													className={`${post.shared_post.shared_user_post.images.length === 1
														? 'feed-image'
														: ''
														} ${post.shared_post.shared_user_post.images.length === 2
															? 'feed-image feed-col2'
															: ''
														} ${post.shared_post.shared_user_post.images.length === 3
															? 'feed-gallery feed-col3'
															: ''
														} ${post.shared_post.shared_user_post.images.length === 4
															? 'feed-gallery feed-col4'
															: ''
														} ${post.shared_post.shared_user_post.images.length > 4
															? 'feed-gallery feed-multiple'
															: ''
														} mb-2`}
												>

													{post.shared_post.shared_user_post.images.map((file, ii) => {
														return (
															<React.Fragment key={ii}>
																{post.shared_post.shared_user_post.images.length > 4 ? (
																	ii > 3 ? (
																		<a href={file.file_name} key={ii} className='d-none' data-fancybox={'feed_userid_' + pi}>
																			<img src={file.file_name} alt='' className='w-100 h-100' />
																		</a>
																	) : (
																		<a href={file.file_name} key={ii} data-fancybox={'feed_userid_' + pi}>
																			<img src={file.file_name} alt='' className='w-100 h-100'

																			/>
																			{ii === 3 && (
																				<span className='feed-more-gal'>
																					{post.shared_post.shared_user_post.images.length - 4}+
																				</span>
																			)}
																		</a>
																	)
																) : (
																	<a href={file.file_name} data-fancybox={'feed_userid_' + pi}>
																		<img src={file.file_name} alt='' className='w-100 h-100'

																		/>
																	</a>
																)}
															</React.Fragment>
														);
													})}
													{/* {post.shared_post.shared_user_post.images.length > 4 && (
														<div className='d-none'>
															{post.images.map((file, ii) => {
																return (
																	<a href={file.file_name} key={ii} data-fancybox={'feed_userid_'+pi}
																		
																	>
																		<img src={file.file_name} alt='' className='w-100 h-100' />
																	</a>
																);
															})}
														</div>
													)} */}


												</div>

											)}
											{post.shared_post.shared_user_post.videos.length > 0 && (
												<React.Fragment>
													{post.shared_post.shared_user_post.videos.map((file, ii) => {
														return (
															<React.Fragment key={ii}>
																<div className='feed-video position-relative'>
																	<video
																		controls
																		width='100%'
																		className='d-block'
																		id={`video_${file.id}`}
																	>
																		<source src={file.file_name} type='video/mp4' />
																	</video>
																	{/* <a
                                                onClick={(e) => e.preventDefault()} id={`a_${file.id}`}
                                                className="play-video c-p position-absolute w-100 h-100 align-items-center justify-content-center top-0"
                                            >
                                                <img src="../assets/img/icon/video-play.svg" alt="" />
                                            </a> */}
																</div>
															</React.Fragment>
														);
													})}
												</React.Fragment>
											)}
										</React.Fragment>
										<div

										>
											<div className='d-flex align-items-start feed-top mb-2 c-p'
												onClick={(e) => {
													e.preventDefault();
													navigateUserHome(post.shared_post.shared_user_post.user);
												}}
											>
												<span className='feed-user-image'

												>
													<img
														src={
															post.shared_post.shared_user_post.user?.image ? post.shared_post.shared_user_post.user?.image : '../assets/img/edit-profile.png'
														}
														alt=''
														width='48'
														height='48'
														className='rounded-circle w-40'
													/>
												</span>
												<div className='ps-2 pt-1'>
													<h6 className='semibold text-black fs-15'>{post.shared_post.shared_user_post.user?.name}</h6>
													<span className='fs-13 text-lightgray'>
														{post.shared_post.shared_user_post.user?.user_sports?.name} | {post.shared_post.shared_user_post.created_date} | {post.shared_post.shared_user_post.time}
													</span>
												</div>

											</div>
											<div className='feed-desc pb-2'>
												<p className='m-0 text-696969'>
													{post.shared_post.shared_user_post.name && (
														<React.Fragment>
															{post.id == showMore ?
																post.shared_post.shared_user_post.name :
																post.shared_post.shared_user_post.name.slice(0, 140)}{' '}
															{post.shared_post.shared_user_post.name.length > 140 && (
																post.id == showMore ?
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			// let newPost = { ...post.shared_post.shared_user_post };
																			// newPost['showFull'] = !post.shared_post.shared_user_post.showFull;
																			// let newPostList = [...postList];
																			// newPostList[pi] = newPost;
																			// setPostList(newPostList);
																			setShowMore('');
																		}}
																		className='text-green c-p'
																	>
																		Read Less
																	</a>
																	:
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			// let newPost = { ...post.shared_post.shared_user_post };
																			// newPost['showFull'] = !post.shared_post.shared_user_post.showFull;
																			// let newPostList = [...postList];
																			// newPostList[pi] = newPost;
																			// setPostList(newPostList);
																			setShowMore(post.id);
																		}}
																		className='text-green c-p'
																	>
																		Read More
																	</a>
															)}
														</React.Fragment>
													)}
												</p>
											</div>
										</div>
									</div>
								)}
								<div className='feed-react-bar d-flex align-items-center justify-content-between pt-2'>
									<div className='d-flex align-items-center '>
										<span className='text-lightgray fs-13'>
											<img
												src='../assets/img/icon/like1.svg'
												alt=''
												width='20'
												height='20'
												className='me-1'
											/>
											{/* 100+ */}
											{post.user_post_likes_count > 100
												? '100+'
												: post.user_post_likes_count}
										</span>
									</div>

									<div>
										<span className=' me-xl-4 me-2'>
											<span className='text-lightgray fs-13'>
												<img
													src='../assets/img/icon/messagetext1.svg'
													alt=''
													width='20'
													height='20'
													className='me-1'
												/>{' '}
												{post.post_comments_count} comments
											</span>
										</span>

										<span className=''>
											<span
												className='text-lightgray fs-13 c-p'
												onClick={(e) => {
													if (post.shared_all_post_count > 0) {
														setSelectedPost(post);
														$('#sharepost').modal('show');
													}
												}}
											>
												<img
													src='../assets/img/icon/share-forward-fill.svg'
													alt=''
													width='20'
													height='20'
													className='me-1'
												/>
												{post.shared_all_post_count} Share
											</span>
										</span>
									</div>
								</div>

								<div className='py-2 mt-2 d-flex align-items-center justify-content-between border-top border-bottom fs-14'>
									<span className='text-696969 c-p' onClick={(e) => addLike(post, pi)}>
										<img
											src='../assets/img/icon/like.svg'
											alt=''
											width='25'
											height='25'
											className=''
										/>{' '}
										Like
									</span>
									<span className='text-696969 c-p'
										onClick={() => {
											setSelectedPost(post)
										}}
										data-bs-toggle='modal'
										data-bs-target='#commentModal'
									>
										<img
											src='../assets/img/icon/messagetext.svg'
											alt=''
											width='25'
											height='25'
											className='rounded-circle'
										/>{' '}
										Comment
									</span>
									<span
										className='text-696969 c-p'
										onClick={(e) => {
											$('#shareNewPost').modal('show');
											setNewPostComment(post.name);
											setOldPostComment(post.name);
											setSelectedPost(post);
											console.log(post);
										}}
									>
										<img
											src='../assets/img/icon/share-forward-line.svg'
											alt=''
											width='25'
											height='25'
										/>{' '}
										Share
									</span>
								</div>

								<div className='feed-comment-add d-flex align-items-center w-100 px-1 py-2 gap-2'>
									<div>
									<div
									style={{
										width: '40px',
										height: '40px'
									}}
									// className='user-icon'
									>
										<img
											src={
												props.user?.image ? props.user?.image : '../assets/img/edit-profile.png'
											}
											// width='40'
											// height='40'
											style={{
												width: '100%',
												height: '100%', 
												objectFit: 'cover',
												borderRadius: '100%'
											}}
											alt=''
											// className='rounded-circle w-40'
										/>
									</div>
									</div>
									<form
										onSubmit={(e) => {
											e.preventDefault();
											onPostCommentClick(post);
										}}
										onClick={(e) => setSelectedPost(post)}
										data-bs-toggle='modal'
										data-bs-target='#commentModal'
										className=' w-100 rounded-pill  bg-lightgreen d-flex align-items-center justify-content-between px-3 py-2'
									>
										<input
											type='text'
											className='fs-13 border-0 bg-transparent text-green'
											placeholder='Write a comment'
										/>
										<span className='d-flex'>
											<span className='me-3'>
												<input type='file' id='feed-image-a' className='d-none' />
												<label htmlFor='feed-image'>
													<img src='../assets/img/icon/camera.svg' alt='' />
												</label>
											</span>
											<Link className='send'>
												<img src='../assets/img/icon/send.svg' alt='' />
											</Link>
										</span>
									</form>
								</div>
								{post?.post_comments?.length > 0 && (
									<React.Fragment>
										<div 
										// className='d-flex'
										className='feed-comment-box d-flex align-items-center w-100 px-2 py-1 gap-2'
										>
											<div style={{
												// backgroundColor: 'yellow'
											}}>
											<div 
											style={{
												width: '40px',
												height: '40px',
												// backgroundColor: 'red',
												// borderRadius: '100%'
											}}
											className='c-p' onClick={() => {
												navigateUserHome(post.post_comments[0].user)
											}}>
												<img
													src={
														post.post_comments[0].user?.image
															? post.post_comments[0].user?.image
															: '../assets/img/edit-profile.png'
													}
													style={{
														width: '100%',
														height: '100%', 
														objectFit: 'cover',
														borderRadius: '50%'
													}}
													// width='40'
													// height='40'
													alt=''
													// className='rounded-circle w-40'
												/>
											</div>
											</div>
											<div className='bg-lightgreen rounded-8 p-2 w-100'>
												<span className="c-p" onClick={() => {
													navigateUserHome(post.post_comments[0].user)
												}}>
													<h6 className='medium mb-1'>{post.post_comments[0].user?.name}</h6>
												</span>
												<p className='fs-13 text-696969 mb-2'>
													{post.post_comments[0].comment}
												</p>
												{
													post.post_comments[0]?.post_comment_file?.file_name && 
													post.post_comments[0]?.post_comment_file?.file_type == "image"?
													<img
													src={post.post_comments[0]?.post_comment_file?.file_name}
													style={{
														height: '60px',
														width: '80px',
														objectFit: 'cover',
														borderRadius: '10px',
														marginBottom: '5px'
													}}
													/>
													: 
													post.post_comments[0]?.post_comment_file?.file_name && 
													post.post_comments[0]?.post_comment_file?.file_type == "video"?
													<video
														controls
														width='50%'
														className='d-block'
														style={{
															borderRadius: '10px',
															marginBottom: '10px'
														}}
													>
														<source src={post.post_comments[0]?.post_comment_file?.file_name} type='video/mp4' />
													</video>
													: 
													null
												}
												<p className='fs-13 text-lightgray'>
													{post.post_comments[0].created_date} | {post.post_comments[0].time}
												</p>
											</div>
										</div>
										<div className='d-flex align-items-center justify-content-between mt-2'>
											{post.post_comments.length > 1 ? (
												<h6
													className='text-green regular c-p'
													onClick={(e) => {
														e.preventDefault();
														setSelectedPost(post);
														$('#viewAllComment').modal('show');
													}}
												>
													View All Comments
												</h6>
											) : (
												<h6 className='text-green regular'></h6>
											)}
											<h6 className='text-lightgray regular'>
												1 of {post.post_comments.length}
											</h6>
										</div>
									</React.Fragment>
								)}
							</li>
						);
					})}
				</ul>
				{props.pageFrom !== 'feed' &&
					postInfo.current_page !== postInfo.last_page && (
						<a
							className='text-green c-p'
							onClick={(e) => {
								e.preventDefault();
								loadMoreData(e);
							}}
						>
							View More Posts
						</a>
					)}
			</InfiniteScroll>
			{/* <!-- Modal --> */}
			<div
				className='modal fade '
				id='createpost'
				tabIndex='-1'
				aria-labelledby='createpostmodal'
				aria-hidden='true'
				data-bs-backdrop='static'
				data-bs-keyboard='false'
			>
				<div className='modal-dialog modal-dialog-scrollable'>
					<div className='modal-content border-0 rounded-10'>
						<div className='modal-header border-0 p-2'>
							<button
								type='button'
								className='modal-close bg-transparent border-0'
								onClick={(e) => {
									if (
										oldPostComment != newPostComment ||
										oldPostImages.length != postImages.length ||
										oldPostVideos.length != postVideos.length
									) {
										Swal.fire({
											title: 'Are you sure?',
											text: 'Are you sure you want to discard this post',
											icon: 'warning',
											showCancelButton: true,
											confirmButtonColor: '#3085d6',
											confirmButtonText: 'Yes',
										}).then((result) => {
											if (result.isConfirmed) {
												setNewPostComment('');
												setPostImages([]);
												setPostVideos([]);
												setPostFiles([]);
												setSelectedPost(null);
												$('#createpost').modal('hide');
											}
										});
									} else {
										$('#createpost').modal('hide');
									}
								}}
							>
								<img src='../assets/img/icon/close-circle.svg' alt='' />
							</button>
						</div>
						<div className='modal-body'>
							<h3 className='text-darkgray pb-3 text-center'>
								{selectedPost ? 'Update Post' : 'Create Post'}
							</h3>
							<form onSubmit={onPostSubmit} className='site-form'>
								<div className='form-field mb-2'>

									<textarea
										value={newPostComment}
										name='newPostComment'
										onChange={(e) => setNewPostComment(e.target.value)}
										rows='4'
										className='bg-lightgreen border-0'
										placeholder={`What’s on your mind, ${props.user?.name} ${props.user?.last_name ? props.user?.last_name : ''
											}?`}
										id='addEditCommentBox'
									></textarea>
								</div>
								<div className='form-field mb-2'>
									<input
										name='myImage'
										type='file'
										className='d-none'
										onChange={(e) => {
											onPictureChange(e);
										}}
										id='d-file'
										multiple
									/>
									<label
										htmlFor='d-file'
										className='upload-file text-green bg-lightgreen d-inline-block fs-16 medium text-center px-4'
									>
										<img src='../assets/img/icon/camera.svg' className='me-2' alt='' />
										Add photos/videos
									</label>
								</div>
								<div className=''>
									<div className='row m-0 gx-2'>
										{postImages.map((image, i) => {
											return (
												<div
													key={i}
													className={`${postImages.length > 2 ? 'col-6' : 'col-12'
														} position-relative pb-1 ps-0`}
												>
													<img src={image.file_name} alt='' className='w-100 h-100' />
													<a
														onClick={(e) => {
															removeFile(image);
															deletePostFile(image)
														}}
														className='position-absolute c-p top-0 end-0 p-2'
													>
														<i className='fa fa-xmark-circle'></i>
													</a>
												</div>
											);
										})}
										{postVideos.length > 0 && (
											<React.Fragment>
												{/* <div className='row m-0 gx-2'> */}
												{postVideos.map((file, ii) => {
													return (
														<React.Fragment key={ii}>
															<div
																className={`${postImages.length > 2 ? 'col-6' : 'col-12'
																	} feed-video position-relative pb-3 ps-0`}
															>
															
																<video
																	controls
																	// height='100%'
																	// width='100%'
																	className='w-100 h-100'
																	style={{ objectFit: 'cover' }}
																>
																	<source
																		src={
																			file.isNew ? URL.createObjectURL(file.file) : file.file_name
																		}
																	/>
																</video>
																{file.url}
																<a
																	onClick={(e) => {
																		removeFile(file);
																		deletePostFile(file);
																	}}
																	className='position-absolute c-p top-0 end-0 p-2'
																>
																	<i className='fa fa-xmark-circle'></i>
																</a>

															</div>
														</React.Fragment>
													);
												})}
												{/* </div> */}
											</React.Fragment>
										)}
									</div>

								</div>
								<button
									className='butn w-100 rounded-pill border-0'
									disabled={
										!newPostComment && postImages.length == 0 && postVideos.length == 0
									}
									type='submit'
								>
									{loading? <Loader isButton={true}/> : (selectedPost ? 'Save' : 'Post')}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- End Modal --> */}
			<div
				className='modal fade '
				id='commentModal'
				tabIndex='-1'
				aria-labelledby='createCommentmodal'
				aria-hidden='true'
				data-bs-backdrop='static'
				data-bs-keyboard='false'
			>
				<div className='modal-dialog modal-dialog-scrollable'>
					<div className='modal-content border-0 rounded-10'>
						<div className='modal-header border-0 p-2'>
							<button
								type='button'
								className='modal-close bg-transparent border-0'
								data-bs-dismiss='modal'
								onClick={(e) => {
									setNewPostComment('');
									setPostFiles([]);
									setPostImages([]);
									setPostVideos([]);
									setSelectedPost(null);
								}}
								aria-label='Close'
							>
								<img src='../assets/img/icon/close-circle.svg' alt='' />
							</button>
						</div>
						<div className='modal-body'>
							<h3 className='text-darkgray pb-3 text-center'>Create Post Comment</h3>
							<form onSubmit={onPostCommentSubmit} className='site-form'>
								<div className='form-field mb-2'>
									<textarea
										id='postCommentBox'
										autoFocus={true}
										value={newPostComment}
										name='newPostComment'
										onChange={(e) => setNewPostComment(e.target.value)}
										rows='4'
										className='bg-lightgreen border-0'
										placeholder='Write a comment'
									></textarea>
								</div>
								<div className='form-field mb-2'>
									<input
										name='myImage'
										type='file'
										className='d-none'
										onChange={(e) => {
											onPictureChange(e);
										}}
										id='file'
										multiple
									/>
									<label
										htmlFor='file'
										className='upload-file text-green bg-lightgreen d-inline-block fs-16 medium text-center px-4'
									>
										<img src='../assets/img/icon/camera.svg' className='me-2' alt='' />
										Add photos/videos
									</label>
								</div>
								<div className=''>
									{postImages.length > 0 && (
										<div className='position-relative pb-3'>
											<img src={postImages[0].file_name} alt='' className='w-100' />
											<a
												onClick={(e) => {
													removeFile(postImages[0]);
													deletePostFile(postImages[0])
												}}
												className='position-absolute top-0 end-0 p-2'
											>
												<i className='fa fa-xmark-circle'></i>
											</a>
										</div>
									)}
									<div className='row m-0 gx-2'>
										{postImages.map((image, i) => {
											return i == 0 ? (
												<React.Fragment key={i}></React.Fragment>
											) : (
												<div key={i} className='col-12 position-relative pb-3 ps-0'>
													<img src={image.file_name} alt='' className='w-100' />
													<a
														onClick={(e) => {
															removeFile(image);
															deletePostFile(image)
														}}
														className='position-absolute top-0 end-0 p-2'
													>
														<i className='fa fa-xmark-circle'></i>
													</a>
												</div>
											);
										})}
										{postVideos.length > 0 && (
										<React.Fragment>
											{/* <div className='row m-0 gx-2'> */}
												{postVideos.map((file, ii) => {
													return (
														<React.Fragment key={ii}>
															<div className='col-12 feed-video position-relative pb-3 ps-0'>
																<video
																	controls
																	width='100%'
																	className='d-block'
																	id={`video_${file.id}`}
																>
																	<source src={file.file_name} type='video/mp4' />
																</video>
																<a
																	onClick={(e) => {
																		removeFile(file);
																		deletePostFile(file)
																	}
																	}
																	className='position-absolute top-0 end-0 p-2'
																>
																	<i className='fa fa-xmark-circle'></i>
																</a>
																{/* <a
                                                    onClick={(e) => e.preventDefault()} id={`a_${file.id}`}
                                                    className="play-video c-p position-absolute w-100 h-100 align-items-center justify-content-center top-0"
                                                >
                                                    <img src="../assets/img/icon/video-play.svg" alt="" />
                                                </a> */}
															</div>
														</React.Fragment>
													);
												})}
											{/* </div> */}
										</React.Fragment>
									)}
									</div>
									
								</div>
								<button
									className='butn w-100 rounded-pill border-0'
									disabled={
										!newPostComment && postImages.length == 0 && postVideos.length == 0
									}
									type='submit'
								>
									{commentLoading? <Loader isButton={true}/> : "Post Comment"}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Modal --> */}
			<div
				className='modal fade '
				id='shareNewPost'
				tabIndex='-1'
				aria-labelledby='shareNewPost'
				aria-hidden='true'
				data-bs-backdrop='static'
				data-bs-keyboard='false'
			>
				<div className='modal-dialog modal-dialog-scrollable'>
					<div className='modal-content border-0 rounded-10'>
						<div className='modal-header border-0 p-2'>
							<button
								type='button'
								className='modal-close bg-transparent border-0'
								data-bs-dismiss='modal'
								onClick={(e) => {
									setNewPostComment('');
									setNewSharePostComment('');
									setPostImages([]);
									setPostVideos([]);
									setPostFiles([]);
									setSelectedPost(null);
								}}
								aria-label='Close'
							>
								<img src='../assets/img/icon/close-circle.svg' alt='' />
							</button>
						</div>
						<div className='modal-body'>
							<h3 className='text-darkgray pb-3 text-center'>Share Post</h3>
							<form onSubmit={onSharePostSubmit} className='site-form'>
								<div className='form-field mb-2'>
									<textarea
										value={newSharePostComment}
										name='newPostComment'
										onChange={(e) => setNewSharePostComment(e.target.value)}
										rows='4'
										className='bg-lightgreen border-0'
										placeholder='Write a comment'
									></textarea>
								</div>

								{selectedPost && (
									<div className=''>
										{selectedPost.images.length > 0 && (
											<div
												className={`${selectedPost.images.length === 1 ? 'feed-image' : ''
													} ${selectedPost.images.length === 2 ? 'feed-image feed-col2' : ''
													} ${selectedPost.images.length === 3 ? 'feed-gallery feed-col3' : ''
													} ${selectedPost.images.length === 4 ? 'feed-gallery feed-col4' : ''
													} ${selectedPost.images.length > 4 ? 'feed-gallery feed-multiple' : ''
													} mb-2`}
											>
												{selectedPost.images.map((file, ii) => {
													return (
														<React.Fragment key={ii}>
															{selectedPost.images.length > 4 ? (
																ii > 3 ? (
																	''
																) : (
																	<a href={file.file_name} data-fancybox='feed_userid'>
																		<img src={file.file_name} alt='' className='w-100 h-100' />
																		{ii === 3 && (
																			<span className='feed-more-gal'>
																				{selectedPost.images.length - 4}+
																			</span>
																		)}
																	</a>
																)
															) : (
																<img src={file.file_name} alt='' className='w-100 h-100' />
															)}
														</React.Fragment>
													);
												})}
												{selectedPost.images.length > 4 && (
													<div className='d-none'>
														{selectedPost.images.map((file, ii) => {
															return (
																<a href={file.file_name} key={ii} data-fancybox='feed_userid'>
																	<img src={file.file_name} alt='' className='w-100 h-100' />
																</a>
															);
														})}
													</div>
												)}
											</div>
										)}
										{selectedPost.videos.length > 0 && (
											<React.Fragment>
												{selectedPost.videos.map((file, ii) => {
													return (
														<React.Fragment key={ii}>
															<div className='feed-video position-relative'>
																<video
																	controls
																	width='100%'
																	height='100%'
																	className='d-block'
																	id={`video_${file.id}`}
																>
																	<source src={file.file_name} type='video/mp4' />
																</video>
															</div>
														</React.Fragment>
													);
												})}
											</React.Fragment>
										)}
									</div>
								)}
								{selectedPost && selectedPost.shared_post && (
									<div className=''>
										{selectedPost.shared_post.shared_user_post.images.length > 0 && (
											<div
												className={`${selectedPost.shared_post.shared_user_post.images.length === 1
													? 'feed-image'
													: ''
													} ${selectedPost.shared_post.shared_user_post.images.length === 2
														? 'feed-image feed-col2'
														: ''
													} ${selectedPost.shared_post.shared_user_post.images.length === 3
														? 'feed-gallery feed-col3'
														: ''
													} ${selectedPost.shared_post.shared_user_post.images.length === 4
														? 'feed-gallery feed-col4'
														: ''
													} ${selectedPost.shared_post.shared_user_post.images.length > 4
														? 'feed-gallery feed-multiple'
														: ''
													} mb-2`}
											>
												{selectedPost.shared_post.shared_user_post.images.map(
													(file, ii) => {
														return (
															<React.Fragment key={ii}>
																{selectedPost.shared_post.shared_user_post.images.length > 4 ? (
																	ii > 3 ? (
																		''
																	) : (
																		<a href={file.file_name} data-fancybox='feed_userid'>
																			<img src={file.file_name} alt='' className='w-100 h-100' />
																			{ii === 3 && (
																				<span className='feed-more-gal'>
																					{selectedPost.shared_post.shared_user_post.images.length -
																						4}
																					+
																				</span>
																			)}
																		</a>
																	)
																) : (
																	<img src={file.file_name} alt='' className='w-100 h-100' />
																)}
															</React.Fragment>
														);
													}
												)}

											</div>
										)}
										{selectedPost.shared_post.shared_user_post.videos.length > 0 && (
											<React.Fragment>
												{selectedPost.shared_post.shared_user_post.videos.map(
													(file, ii) => {
														return (
															<React.Fragment key={ii}>
																<div className='feed-video position-relative'>
																	<video
																		controls
																		width='100%'
																		height='100%'
																		className='d-block'
																		id={`video_${file.id}`}
																	>
																		<source src={file.file_name} type='video/mp4' />
																	</video>

																</div>
															</React.Fragment>
														);
													}
												)}
											</React.Fragment>
										)}
									</div>
								)}
								<div className='feed-desc pb-2'>
									{selectedPost && (
										<>
											{selectedPost?.shared_post ?
												<p className='m-0 text-696969'>

													{selectedPost?.shared_post?.shared_user_post.name && (
														<React.Fragment>
															{selectedPost.showFull
																? selectedPost?.shared_post?.shared_user_post.name
																: selectedPost?.shared_post?.shared_user_post.name.slice(0, 140)}{' '}
															{selectedPost?.shared_post?.shared_user_post.name.length > 140 && (
																<a
																	onClick={(e) => {
																		e.preventDefault();
																		let newPost = { ...selectedPost?.shared_post?.shared_user_post };
																		newPost['showFull'] = !selectedPost.showFull;
																		setSelectedPost(newPost);
																	}}
																	className='text-green c-p'
																>

																	{selectedPost.showFull ? 'Read Less' : 'Read More'}
																</a>
															)}
														</React.Fragment>
													)}
												</p>
												:
												<p className='m-0 text-696969'>

													{selectedPost.name && (
														<React.Fragment>
															{selectedPost.showFull
																? selectedPost.name
																: selectedPost.name.slice(0, 140)}{' '}
															{selectedPost.name.length > 140 && (
																<a
																	onClick={(e) => {
																		e.preventDefault();
																		let newPost = { ...selectedPost };
																		newPost['showFull'] = !selectedPost.showFull;
																		setSelectedPost(newPost);
																	}}
																	className='text-green c-p'
																>
																	{selectedPost.showFull ? 'Read Less' : 'Read More'}
																</a>
															)}
														</React.Fragment>
													)}
												</p>
											}
										</>
									)}
								</div>
								<button
									className='butn w-100 rounded-pill border-0 mt-2'
									disabled={
										!newPostComment &&
										selectedPost &&
										selectedPost.videos.length == 0 &&
										selectedPost.images.length == 0
									}
									type='submit'
								>
									Share
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- End Modal --> */}
			{/* <!-- Modal --> */}
			<div
				className='modal fade '
				id='sharepost'
				tabIndex='-1'
				aria-labelledby='sharepostmodal'
				aria-hidden='true'
				data-bs-backdrop='static'
				data-bs-keyboard='false'
			>
				<div className='modal-dialog '>
					<div className='modal-content border-0 rounded-10'>
						<div className='modal-header border-0 p-2'>
							<button
								type='button'
								className='modal-close bg-transparent border-0'
								data-bs-dismiss='modal'
								aria-label='Close'
								onClick={(e) => {
									setSelectedPost(null);
								}}
							>
								<img src='../assets/img/icon/close-circle.svg' alt='' />
							</button>
						</div>

						<div className='modal-body'>
							<h3 className='text-darkgray pb-3'>People who shared this</h3>
							{selectedPost &&
								selectedPost.shared_all_post &&
								selectedPost.shared_all_post.length > 0 && (
									<React.Fragment>
										{selectedPost.shared_all_post.map((sharpost, si) => {
											return (
												<div
													key={si}
													className='d-flex justify-content-between align-items-center people-list rounded-10 mb-3'
												>
													<div className='d-flex gap-3  align-items-center'>
														<img
															src={
																sharpost.user?.image
																	? sharpost.user?.image
																	: '../assets/img/edit-profile.png'
															}
															width='48'
															height='48'
															alt=''
														/>
														<div>
															<h6 className='semibold text-darkgray'>{sharpost.user?.name}</h6>
															<span className='fs-12 regular text-lightgray d-block'>
																{sharpost.user?.user_sports.name}
															</span>
														</div>
													</div>
													<span className='fs-12 regular text-lightgray d-block'>
														{`${new Date(sharpost.created_at).getDate()} ${monthNames[new Date(sharpost.created_at).getMonth()]
															} ${new Date(sharpost.created_at).getFullYear()}`}{' '}
														| {`${formatAMPM(new Date(sharpost.created_at))}`}
													</span>
												</div>
											);
										})}
									</React.Fragment>
								)}
						</div>
					</div>
				</div>
			</div>
			{/* <!-- End Modal --> */}
			{/* <!-- Modal --> */}
			<div
				className='modal fade '
				id='viewAllComment'
				tabIndex='-1'
				aria-hidden='true'
				data-bs-backdrop='static'
				data-bs-keyboard='false'
			>
				<div className='modal-dialog modal-dialog-scrollable'>
					<div className='modal-content border-0 rounded-10'>
						<div className='modal-header border-0 p-2'>
							<button
								type='button'
								className='modal-close bg-transparent border-0'
								data-bs-dismiss='modal'
								aria-label='Close'
								onClick={(e) => setSelectedPost(null)}
							>
								<img src='../assets/img/icon/close-circle.svg' alt='' />
							</button>
						</div>

						<div className='modal-body'>
							<h3 className='text-darkgray pb-3'>All Comments</h3>
							{selectedPost && selectedPost.post_comments.length > 0 && (
								<React.Fragment>
									{selectedPost.post_comments.map((item, i) => {
										return (
											<div
												className='feed-comment-box d-flex align-items-center w-100 px-2 py-1 gap-2'
												key={i}
											>
												<span className='user-icon c-p'
													data-bs-dismiss='modal'
													aria-label='Close'
													onClick={() => {
														navigateUserHome(item.user);
														setSelectedPost(null);
													}}
												>
													<img
														src={
															item.user?.image ? item.user?.image : '../assets/img/edit-profile.png'
														}
														width='40'
														height='40'
														alt=''
														className='rounded-circle w-40'
													/>
												</span>
												<div className='bg-lightgreen rounded-8 p-2 w-100'>
													<span className="c-p"
														data-bs-dismiss='modal'
														aria-label='Close'
														onClick={() => {
															navigateUserHome(item.user);
															setSelectedPost(null);
														}}
													>

														<h6 className='medium mb-1'>{item.user?.name}</h6>
													</span>

													<p className='fs-13 text-696969 mb-2'>{item.comment}</p>
													{
													item?.post_comment_file?.file_name &&
													item?.post_comment_file?.file_type == "image"?
													<img
													src={item?.post_comment_file?.file_name}
													style={{
														height: '60px',
														width: '80px',
														objectFit: 'cover',
														borderRadius: '10px',
														marginBottom: '5px'
													}}
													/>
													: 
													item?.post_comment_file?.file_name &&
													item?.post_comment_file?.file_type == "video"?
													<video
														controls
														width='50%'
														className='d-block'
														style={{
															borderRadius: '10px',
															marginBottom: '10px'
														}}
													>
														<source src={item?.post_comment_file?.file_name} type='video/mp4' />
													</video>
													: 
													null
													}
													<p className='fs-13 text-lightgray'>
														{item.created_date} | {item.time}
													</p>
												</div>
											</div>
										);
									})}
								</React.Fragment>
							)}
						</div>
					</div>
				</div>
			</div>
			{/* <!-- End View Comment Modal --> */}

		</React.Fragment>
	);
}
