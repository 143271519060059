import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import { ValidateList, ValidationTypes } from "../../Utils/ValidationHelper";
import { setCookie } from "../../helpers/authUtils";
import { postCall } from "../../helpers/axiosUtils";
import { connect } from 'react-redux';
import { loginUserSuccess } from '../../redux/actions';
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import FacebookLogin from 'react-facebook-login';
import Loader from "../../Components/loader";
function Login(props) {
  const history = useNavigate();

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [googleResponse, setGoogleResponse] = useState("");
  const [cPasswordVisiblity, setCPasswordVisiblity] = useState(false);
  const [socialLoginType, setSocialLoginType] = useState("");
  const [loading, setLoading] = useState(false);

  const Signin = async (e) => {
    e.preventDefault();
    let validate_arr = [
      [
        phone,
        ValidationTypes.Empty,
        "Please enter a valid email or mobile number",
      ],
      [password, ValidationTypes.Password, "Please enter a valid password"],
    ];
    let validate = await ValidateList(validate_arr);
    if (!validate) {
      return;
    }
    let obj = {
      password: password,
      email: phone,
      // customer_selected_role: "User",
    };

    try {
      setLoading(true);
      let resp = await FetchApi(Endpoints.login, obj);
      if (resp && resp.type === "success") {
     
        if (resp.access_token) {
          setCookie('token', resp.access_token)
          
          let options = {
            'content-type': 'application/json',
            AUTHORIZATION: `Bearer ${resp.access_token}`
          };
          const url = Endpoints.baseUrl + Endpoints.userDetail
          postCall(url, {}, { headers: options }).then(r => {
            props.loginUserSuccess(r.data.data);
            // console.log('checkuserlog_', JSON.stringify(r.data.data, null, 4));
            localStorage.setItem('loggedUser', JSON.stringify(r.data.data));
            setCookie('user', JSON.stringify(r.data.data));
            const redirectURL = localStorage.getItem('redirectAfterLogin');
            if(redirectURL){
              localStorage.removeItem('redirectAfterLogin');
              // history(redirectURL)
              window.location.href = redirectURL
            }
            else{
              history('/feeds')
            }
          }).catch(error => {
            history('/')
          })
          setLoading(false);
        }
        else {
          setLoading(false);
          ToastMessage.Success(resp.message);
          const data = resp.data;
          let redirectUrl = ''
          if (data.customer_selected_role === 'User') {
            redirectUrl = '/user-complete-profile'
          }
          else if (data.customer_selected_role === 'Coach') {
            redirectUrl = '/coach-profile-complete'
          }
          else if (data.customer_selected_role === 'Facilities') {
            redirectUrl = '/facility-profile-complete'
          }
          data['fname'] = data.name;
          data['mobile'] = data.phone
          data['user_slug'] = { user_slug: data.user_slug }
          history(redirectUrl, { state: data });
        }

      }

      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
      if (resp.status === "false") {
        Swal.fire({
          text: typeof resp.message === 'object' ? resp.message.email[0] : resp.message,
          icon: "error",
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      console.log(tokenResponse);
      axios
      .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`, {
          headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: 'application/json'
          }
      })
      .then((res) => {
        handleSocialLogin(res.data);
      })
      .catch((err) => console.log(err));
    },
  
  });

  const handleSocialLogin  = async(data, login_type) =>{
      let obj ={
          email:data.email,
          media_login_id:data?.id,
          media_login_type:socialLoginType || login_type,
      }
    try {
      let resp = await FetchApi(Endpoints.login, obj);
      if (resp && resp.type === "success") {
     
        if (resp.access_token) {
          setCookie('token', resp.access_token)
          
          let options = {
            'content-type': 'application/json',
            AUTHORIZATION: `Bearer ${resp.access_token}`
          };
          const url = Endpoints.baseUrl + Endpoints.userDetail
          postCall(url, {}, { headers: options }).then(r => {
            props.loginUserSuccess(r.data.data);
            setCookie('user', JSON.stringify(r.data.data))
            history('/feeds')
          }).catch(error => {
            history('/')
          })
        }
        else {
          ToastMessage.Success(resp.message);
          const data = resp.data;
          let redirectUrl = ''
          if (data.customer_selected_role === 'User') {
            redirectUrl = '/user-complete-profile'
          }
          else if (data.customer_selected_role === 'Coach') {
            redirectUrl = '/coach-profile-complete'
          }
          else if (data.customer_selected_role === 'Facilities') {
            redirectUrl = '/facility-profile-complete'
          }
          data['fname'] = data.name;
          data['mobile'] = data.phone
          data['user_slug'] = { user_slug: data.user_slug }
          history(redirectUrl, { state: data });
        }
      }

      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
      if (resp.status === "false") {
        Swal.fire({
          text: typeof resp.message === 'object' ? resp.message.email[0] : resp.message,
          icon: "error",
        });
      }
    } catch (e) {

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  const responseFacebook = (response) => {
    console.log('fbresplogin___',response);
    setSocialLoginType("facebook");
    handleSocialLogin(response, "facebook");
    // setFacebookResponse(response);
    // $("#selectRoleModel").modal("show");
  };

  useEffect(()=>{
    const Facebutton = document.getElementsByClassName('kep-login-facebook')[0];
    Facebutton.innerHTML = '<img src="../assets/img/icon/facebook.svg" width="30" height="30" alt="" />';       
  }, []);
//  console.log('fb',process.env.REACT_APP_FACEBOOK_ID);

  return (
    <>
      <div className="main py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <h1 className="text-darkgray semibold pb-2 text-center">
                Welcome Back!
              </h1>
              <p className="text-green2 text-center mb-3">
                Welcome Back! please enter your login credentials.
              </p>

              <form onSubmit={Signin} action="" className="site-form pt-2">
                <div className="form-field mb-4">
                  <label htmlFor="" className="pb-1">
                    Email 
                  </label>
                  <input
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    type="text"
                    placeholder="Please Enter Your Email"
                  />
                </div>
                <div className="form-field mb-1 pwd-group position-relative">
                  <label htmlFor="" className="pb-1">
                    Password
                  </label>
                  <input
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    type={cPasswordVisiblity ? 'text' : 'password'}
                    placeholder="Enter password"
                    className="pwd-field"
                  />
                  <a
                    onClick={(e) => setCPasswordVisiblity(!cPasswordVisiblity)}
                    className="pwd-visibility text-gray2 position-absolute "
                  >
                    {/* <Link
                      to={"#!"}
                      className="pwd-visibility text-gray2 position-absolute "
                    > */}
                    <i className={cPasswordVisiblity ? 'fa-light fa-eye-slash' : 'fa-light fa-eye'}></i>
                    {/* </Link> */}
                  </a>
                </div>
                <div className="text-end mb-3">
                  {/* <a href="#" className="text-green"> */}
                  <Link to={"/forgot-password"}>Forgot Password?</Link>
                  {/* </a> */}
                </div>
                <button
                  type="submit"
                  className="butn w-100 rounded-pill border-0"
                >
                  {loading? <Loader isButton={true}/> : "Login"}
                </button>
              </form>

              <div>
                <span className="fs-13 light py-3 d-block text-center">
                  or continue with
                </span>
                <div className="d-flex gap-3 justify-content-center">
                  <Link className="google-login"
                   onClick={() => {
                    setSocialLoginType("google")
                    googleLogin()
                  }
                  }
                  >
                    <img
                      src="../assets/img/icon/google.svg"
                      width="30"
                      height="30"
                      alt=""
                    />
                  </Link>

                  <Link className="google-login"
                 
                  >
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_ID}
                      // appId="1311088136228928"
                      autoLoad={false}
                      fields="name,email,picture"
                      // scope="public_profile,user_friends,user_actions.books,email"
                      scope="public_profile,email"
                      callback={responseFacebook}
                    />
                    {/* <img
                      src="../assets/img/icon/facebook.svg"
                      width="30"
                      height="30"
                      alt=""
                    /> */}
                  </Link>
                </div>
              </div>
              <div className="text-center pt-3">
                <p className="medium text-gray">
                  Don’t you have an account?<Link className="text-green ms-1" to={"/Signup"}>
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    isLoading: state.Auth.isLoading
  }
}

export default connect(mapStateToProps, { loginUserSuccess })(Login);
