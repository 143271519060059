import React, { useEffect } from "react";
import RatingView from "../../Layout/RatingView";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useState } from "react";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import { formatTime } from "../../Utils/constants";
import { Posts } from "../../Layout/Posts";
import SideBar from "../../Layout/SideBar";
import ReactStars from "react-rating-stars-component";
import Promotions from "../Profile/Promotions";
import moment from "moment";
import { getLoggedInUser } from "../../helpers/authUtils";
import { Button, Modal } from "react-bootstrap";
import ToastMessage from "../../Utils/ToastMessage";
import MessageRequestModal from "../../Components/messageRequestModal";
const $ = window.jQuery;

function FacilityProfileOthersView(props) {
  const editProperty = { ...{ edit: true }, ...props }
  const [editProps, editPros] = useState(editProperty)
  const data = useLocation();
  const history = useNavigate();
  const [location, setLocation] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [day, setDay] = useState("");
  const [kidId, setKidId] = useState('');
  const [kidInfo, setKidInfo] = useState(null);
  const [kidsList, setKidsList] = useState([]);
  const [user, setUserInfo] = useState(props.user)
  const [coachInfo, setCoachInfo] = useState()
  const [availabilityList, setAvailabilityList] = useState([])
  const [times, setTimes] = useState([]);
  const params = useParams();
  const [messageDescription, setMsgDescription] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleClose = () => {
    setModalIsOpen(false);
  };

  const loggedInUser = JSON?.parse(localStorage.getItem('loggedUser'));

  // console.log('logeedin__', JSON.stringify(loggedInUser, null ,4))

  const getDayOfWeek = (date) => {
    const dayOfWeek = new Date(date).getDay();
    return isNaN(dayOfWeek) ? null :
      ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][dayOfWeek];
  }
  const getAvailability = async () => {
    try {
      let resp = await FetchApi(Endpoints.getUserAvailability, { user_slug: params?.slug });
      if (resp && resp.type === "success") {
        let avaailabilityList = resp.data
        avaailabilityList.forEach((item) => {
          item.availability_timing.forEach((time) => {
            time['start'] = time.time_from ? formatTime(time.time_from) : ''
            time['end'] = time.time_to ? formatTime(time.time_to) : ''
            time['show'] = time.time_from ? true : false
          })
        })
        setAvailabilityList(avaailabilityList)
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  };
  useEffect(() => {
    getUserInfo();
  }, [params?.slug]);
  const getUserInfo = async () => {
    try {
      let resp = await FetchApi(Endpoints.userDetail, { slug: params?.slug });
      if (resp && resp.type === "success") {
        setCoachInfo(resp?.data);
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  };
  const Submit = (e) => {
    let data = {
      coachData: coachInfo,
      location: location,
      slot: time,
      date: day,
      isFacilite: true,
      kidInfo: kidInfo && kidInfo.id === 'self' ? null : kidInfo
    };
    e.preventDefault();
    $("#coach_availability").modal("hide");
    localStorage.setItem('slug_local', params?.slug);
    history("/booking-summary", { state: data });
  };
  const getKidsList = async () => {
    try {
      let resp = await FetchApi(Endpoints.getUserKids);
      if (resp && resp.type === "success") {
        const kidsList = resp.data
        if (kidsList.length > 0) {
          let data = kidsList[0].user_kids;
          if (data.length > 0) {
            data.push({
              id: 'self',
              name: 'Self'
            })
          }
          setKidsList(data)
        }
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  }
  useEffect(() => {
    getAvailability();
    getKidsList();
  }, []);

  const handleSendMessage = async (id) => {
    let data = {
      chat_receiver_id: id,
      message_description : messageDescription
    }
    try {
      let resp = await CommonAPi(Endpoints.CreateMessageRoomId, data);
      if (resp && resp.status === "success") {
        setModalIsOpen(false)
        getUserInfo();
        Swal.fire({
          text: resp.message,
          icon: "success",
        });
      }
    } catch (e) {
      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };

  const changeStatus = async (Id, status) => {
    let data = {
      message_room_id: Id,
      status: status
    }
    try {
      let resp = await CommonAPi(Endpoints.AcceptDeclineChatRequest, data);
      if (resp && resp.status === "success") {
        getUserInfo();
      }
    } catch (e) {
      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  return (
    <div className="main py-4 ">
      {coachInfo ?
        <div className="container">
          <div className="row feed-page">
            <SideBar />
            {/* <!-- END LEFT COLUMN --> */}

            <div className="col-lg-6 middle-area">
              <div className="border rounded-8 p-3">
                <div className="d-flex gap-3 mb-3 profile-info">
                  <div>
                  <div style={{
                    width: '80px',
                    height: '80px'
                  }}>
                  <img
                    src={
                      coachInfo?.image
                        ? coachInfo?.image
                        : "../assets/img/coach-detail.png"
                    }
                    style={{
                      width: '100%',
                      height: '100%', 
                      objectFit: 'cover',
                      borderRadius: '100%'
                    }}
                    // width="80"
                    // height="80"
                    // className="rounded-pill dp-border"
                    alt=""
                  />
                  </div>
                  </div>
                  <div>
                    <h5 className="semibold">{coachInfo?.name}</h5>
                    <div className="d-flex align-items-center gap-2 pb-2">
                      <span className="d-inline-flex">
                        <ReactStars
                          count={5}
                          onChange={(e) => { }}
                          size={20}
                          isHalf={true}
                          edit={false}
                          value={coachInfo?.reviews_rating ? parseFloat(coachInfo?.reviews_rating) : 0}
                          emptyIcon={<i className="far fa-star"></i>}
                          halfIcon={<i className="fa fa-star-half-alt"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                          activeColor="#ffd700"
                        />
                      </span>
                      <span className="fs-13 medium">
                        {coachInfo?.reviews_rating != null || coachInfo?.reviews_rating > 0 ? parseFloat(coachInfo?.reviews_rating) : 0}{" "}
                        <span className="text-lightgray regular">Ratings</span>{" "}
                        | {coachInfo?.user_reviews?.length > 100 ? '100+' : coachInfo?.user_reviews?.length}{" "}
                        <span className="text-lightgray regular">Reviews</span>
                      </span>

                      {/* <Link href="#">
                      <img src="../assets/img/icon/edit.svg" alt="" />
                    </Link> */}
                    </div>
                    <div className="d-flex gap-2 pb-1 ">
                      <h6 className="regular text-lightgray pb-2" 
                      style={{
                        width: '50%'
                      }}
                      >
                        {coachInfo?.role_id == 4 ? 
                        coachInfo?.facility_selected_amenity?.map((item, index)=>{
                          return(
                            item?.amenity+(index!=coachInfo?.facility_selected_amenity?.length-1? ", ": '')
                          )
                        })
                        :
                        coachInfo?.role_id == 3 ? 
                        coachInfo?.user_speciality?.map((item, index)=>{
                          return(
                            item?.speciality+(index!=coachInfo?.user_speciality?.length-1? ", ": '')
                          )
                        })
                        :
                        coachInfo?.user_sports ? coachInfo?.user_sports.name : ""}
                        {/* {coachInfo?.user_sports ? coachInfo?.user_sports.name : ''} */}
                      </h6>
                      <i className=" text-lightgray">•</i>
                      <p className="fs-12">
                        <i className="fa fa-map-marker-alt text-green pe-1 fs-16"></i>{" "}
                        {coachInfo?.address}
                      </p>
                    </div>
                    {props?.user?.slug != params.slug ?
                      <div className="d-flex gap-2">
                        {coachInfo.isMessageSend == null ?
                          <Link className="butn gradient-butn" onClick={() => {
                            // handleSendMessage(coachInfo?.id)
                            setModalIsOpen(true);
                          }}>
                            Send Message Request
                          </Link>
                          : ""}
                        {coachInfo.isMessageSend == null ?
                          ""
                          :
                          <>
                            {coachInfo.id != coachInfo.isMessageSend?.user_id && coachInfo.isMessageSend?.status == "pending" ?
                              <Link className="butn gradient-butn">
                                Request sent
                              </Link>
                              :
                              ""}
                            {coachInfo.id == coachInfo.isMessageSend?.user_id ?
                              <>
                                {coachInfo.isMessageSend?.status == "pending" ?
                                  <>
                                    <Link className="butn gradient-butn" onClick={() => {
                                      changeStatus(coachInfo.isMessageSend?.id, "active")
                                    }}>
                                      Accept message request
                                    </Link>
                                    <Link className="butn gradient-butn" onClick={() => {
                                      changeStatus(coachInfo.isMessageSend?.id, "decline")
                                    }}>
                                      Reject message request
                                    </Link>
                                  </>
                                  : ""}
                              </>
                              : ""}

                            {coachInfo.isMessageSend?.status == "active" ?
                              <Link className="butn gradient-butn" to={"/message"}>
                                Send message
                              </Link>
                              : ""}

                          </>


                        }
                        {props?.user?.user_role?.name == "Facilities" ?
                          ""
                          :
                          <Link 
                          to={`/view-availability/${params?.slug}`}
                          onClick={() => {
                            localStorage.setItem('coachData', JSON.stringify(coachInfo));
                           }} 
                          // data-bs-target="#coach_availability"
                            // data-bs-toggle="modal" 
                            className="butn"
                            >
                            View Availability
                          </Link>
                        }
                      </div>
                      :

                      <Link className="butn py-2 fs-15" to={'/update-facility-profile'} state={editProps}>Edit Profile</Link>
                    }

                  </div>
                </div>

                <div className="row pe-xl-5 m-0 profile-data">
                  <div className="col-3 pb-3 ps-0">
                    <h3 className="semibold text-green d-flex align-items-center gap-1 ">
                      <img src="../assets/img/icon/clipboardtick.svg" alt="" />
                      {/* {coachInfo?.user_booking_coach_facility_count + coachInfo?.user_bookings_count} */}
                      {coachInfo?.bookedUniqueUserCount || 0}
                    </h3>
                    <h6 className="regular text-green">Bookings</h6>
                  </div>

                  <div className="col-3 text-center pb-3 ps-0">
                    <h3 className="semibold text-green d-flex align-items-center gap-1 justify-content-center">
                      <img src="../assets/img/icon/documenttext.svg" alt="" />
                      {coachInfo?.user_posts_count}
                    </h3>
                    <h6 className="regular text-green">Posts</h6>
                  </div>

                  <div className="col-3 text-center pb-3">
                    <Link to={coachInfo?.id == loggedInUser?.id?
                    "/manage-job": '/job-listing'}>
                    <h3 className="semibold text-green d-flex align-items-center gap-1 justify-content-center">
                      <img src="../assets/img/icon/briefcase.svg" alt="" />
                      {coachInfo.active_user_jobs_count}
                    </h3>
                    <h6 className="regular text-green">Open Jobs</h6>
                    </Link>
                  </div>
                </div>

                <h3 className="medium pb-1">About</h3>
                <p className="text-justify text-696969">
                  {coachInfo?.showFull ? coachInfo?.about : coachInfo?.about?.slice(0, 140)} {coachInfo?.about?.length > 140 &&
                    <a onClick={(e) => {
                      e.preventDefault();
                      let newCoachInfo = { ...coachInfo }
                      newCoachInfo['showFull'] = !newCoachInfo?.showFull;
                      setCoachInfo(newCoachInfo)
                    }} className="text-green c-p"
                      style={{
                        textDecoration: "underline"
                      }}
                    >{coachInfo?.showFull ? 'Read Less' : 'Read More'}</a>}
                </p>
                <h3 className="medium pb-2">Amenities</h3>
                <ul className="taglist d-flex align-items-start gap-2 flex-wrap pb-3">
                  {coachInfo.facility_selected_amenity.map((item, i) => {
                    return (<li key={i}>{item.amenity}</li>)
                  })}
                </ul>

                {/* <h3 className="medium pb-2">Specialities</h3>
                <ul className="taglist d-flex align-items-start gap-2 flex-wrap pb-3">
                  {
                    coachInfo?.user_speciality?.map((item, i) => {
                      return (<li key={i}>{item?.speciality}</li>)
                    })
                  }
                </ul>

                <h3 className="medium pb-2">Facilities</h3>
                <ul className="taglist d-flex align-items-start gap-2 flex-wrap pb-3">
                  {
                    coachInfo?.facility_selected_facility?.map((item, i) => {
                      return (<li key={i}>{item.facility}</li>)
                    })
                  }
                </ul> */}

                <h3 className="medium pb-2">Certificates</h3>
                <ul className="taglist d-flex align-items-start gap-2 flex-wrap pb-3">
                  {coachInfo?.user_certificate?.map((cetrificate, i) => {
                    return (<li key={i}>
                      <a href={cetrificate.image} target="_blank" className="d-flex align-items-start gap-1">
                        <img src="../assets/img/icon/certificate.svg" alt="" />{cetrificate.image_name}
                      </a>
                    </li>)
                  })}
                </ul>
                <h3 className="medium pb-2">Gallery</h3>
                <div className="row gx-sm-1">
                  <div className="col-sm-6 pb-sm-0 pb-2 mb-1">
                    {
                      coachInfo?.facility_image?.length > 0 &&
                      <img
                        src={coachInfo?.facility_image[0]?.image}
                        className="rounded-3 w-100 h-100"
                        alt=""
                      />
                    }
                  </div>
                  <div className="col-sm-6 ">
                    <div className="row gx-sm-1">
                      {coachInfo?.facility_image?.map((item, i) => {
                        return i === 0 ? '' : <React.Fragment key={i}>
                          <div className="col-6 mb-1" >
                            <img
                              src={item.image}
                              className="rounded-3 w-100 h-100"
                              alt=""
                            />
                          </div>
                        </React.Fragment>
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {coachInfo ?
                <Posts user={coachInfo} pageFrom='view' viewUser={coachInfo} />
                : ""}
              <div className="review-section">
                {coachInfo ?
                  <RatingView userInfo={coachInfo} pageFrom={props?.user?.slug == params.slug ? 'profile' : 'details'} />
                  : ""}
              </div>
            </div>
            {/* <!-- END MIDDLE COLUMN --> */}

            <Promotions />
            {/* <!-- END RIGHT COLUMN --> */}
            {/* <!-- Modal --> */}
            <div className="modal fade" id="coach_availability">
              <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content border-0  px-md-3">
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Check Availability</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <form
                      id=""
                      onSubmit={Submit}
                      action=""
                      className="site-form"
                    >
                      {kidsList?.length > 0 && <React.Fragment>
                        <h4 className="semibold pb-2">Choose Athletes</h4>
                        <div className="mb-2">
                          {kidsList?.map((kid, i) => {
                            return (
                              <div className="rounded-3 bg-lightgreen p-2 d-flex align-items-center gap-2 mb-1" key={i}>
                                <input
                                  type="radio"
                                  name="kidsgroup"
                                  className="location-radio check-radio"
                                  value={kid.id}
                                  onChange={(e, kid) => {
                                    const item = kidsList.find(x => x.id == e.target.value)
                                    setKidId(e.target.value)
                                    setKidInfo(item)
                                  }}
                                  checked={kidId == kid.id}
                                />
                                {kid.id != 'self' && <React.Fragment>
                                  <img
                                    src={kid.image ? kid.image : '../assets/img/edit-profile.png'}
                                    width="45"
                                    height="45"
                                    className="rounded-50 rounded-pill"
                                    alt=""
                                  />
                                  <div className="w-75">
                                    <h6 className="semibold">{kid.first_name} {kid.last_name}</h6>
                                    <h6 className="fs-11 text-lightgray regular">Age: {kid.age}yrs</h6>
                                  </div>
                                </React.Fragment>}
                                {kid.id === 'self' && <React.Fragment>
                                  <img
                                    src={user.image ? user.image : '../assets/img/edit-profile.png'}
                                    width="45"
                                    height="45"
                                    className="rounded-50 rounded-pill"
                                    alt=""
                                  />
                                  <div className="w-75">
                                    <h6 className="semibold">{kid.name}</h6>
                                  </div>
                                </React.Fragment>}

                              </div>
                            )
                          })}
                        </div>
                      </React.Fragment>}

                      <h4 className="semibold pb-2">Choose Location</h4>
                      {availabilityList?.map((item, index) => {
                        return (
                          <div key={index} className="form-field mb-3">
                            <div className="pb-2 d-flex align-items-center gap-2">
                              <input
                                type="radio"
                                name="locationgroup"
                                className="location-radio"
                                checked={item.id === location.id}
                                id={item.id}
                                onChange={(e) =>
                                  setLocation(item)
                                }
                              />
                              <label className="fs-13" htmlFor={item.id}>
                                <i className="fa fa-map-marker-alt text-green pe-1 fs-16"></i>{" "}
                                {item.location_name}
                              </label>
                            </div>
                          </div>
                        );
                      })}

                      <div className="form-field pt-2 mb-2 schedule-user">
                        <h4 className="semibold pb-2">
                          Date
                        </h4>
                        <input
                          onChange={(e) => {
                            const value = e.target.value
                            if (value && location) {
                              const blockedDate = location.availability_day_block.find(x => x.blocked_date === value)
                              if (blockedDate) {
                                Swal.fire({
                                  text: "Facility is not Avaliable for selected date",
                                  icon: "error",
                                });
                              }
                              else {
                                const day = getDayOfWeek(value);
                                const times = location.availability_timing.filter(x => x.day_name === day && x.show)
                                setTimes(times)
                                setDay(value)
                              }
                            }
                            else {
                              setTimes([])
                              setDay(value)
                            }
                          }
                          }
                          type="date"
                          value={day}
                          min={moment().format("YYYY-MM-DD")}
                          className="rounded-8 custom-date"
                        />
                      </div>

                      <div className="mb-2 py-3">
                        <h4 className="semibold pb-2">Choose Slot</h4>
                        <div className="time-slot">
                          {times.map((item, index) => {
                            return (
                              <span
                                key={index}
                                className="custom-checkbox"
                              >
                                <input
                                  type="radio"
                                  name="timeslot"
                                  className="d-none"
                                  id={item.id}
                                  checked={time.id === item.id}
                                  onChange={(e) => { setTime(item) }}
                                  value={time}
                                />
                                <label
                                  htmlFor={item.id}
                                  className="fs-15 text-gray border rounded p-2"
                                >
                                  {item.start}
                                </label>
                              </span>
                            );
                          })}
                        </div>
                        {(times?.length == 0 && day) && <div className="text-danger mt-2">No slots avaliable</div>}
                      </div>
                      <button
                        type="submit" disabled={(!time || !location) ? true : false}
                        className="butn w-100 rounded-pill border-0"
                      >
                        Continue
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Modal --> */}
          </div>
        </div>
        : ""}
        <MessageRequestModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        coachInfo={coachInfo}
        handleSendMessage={handleSendMessage}
        messageDescription={messageDescription}
        setMsgDescription={setMsgDescription}
        />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
export default connect(mapStateToProps, {})(FacilityProfileOthersView);
