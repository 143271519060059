import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { useState } from "react";
import SideBar from "../../Layout/SideBar";
import { Posts } from "../../Layout/Posts";
import Promotions from "./Promotions";

function Profile(props) {
  const editProperty = { ...{ edit: true }, ...props }
  const [editProps, editPros] = useState(editProperty)
  const [user, setUserInfo] = useState(props.user)

  console.log('propscheck__', props)
  return (
    <div className="wrapper">
      <div className="main py-4 ">
        <div className="container">
          <div className="row feed-page">
            <SideBar />
            {/* <!-- END LEFT COLUMN --> */}

            <div className="col-lg-6 middle-area">
              <div className="border rounded-8 p-3">
                <div className="d-flex gap-3 mb-3 profile-info">
                  <img
                    src={user.image ? user.image : 'assets/img/edit-profile.png'}
                    width="80"
                    height="80"
                    className="rounded-pill dp-border"
                    alt=""
                  />
                  <div>
                    <h5 className="semibold">{user.name}</h5>

                    <div className="d-flex gap-2 pb-1 align-items-center">
                      <h6 className="regular text-lightgray">
                        {user.user_sports ? user.user_sports.name : ''}
                      </h6>
                      <i className=" text-lightgray">•</i>
                      <p className="fs-12">
                        <i className="fa fa-map-marker-alt text-green pe-1 fs-16"></i>{" "}
                        {user.address}
                      </p>
                    </div>
                    <div className="d-sm-flex align-items-center gap-2">
                      <p className="fs-12 m-0 pb-2">
                        <i className="fa fa-envelope text-green pe-1 fs-14"></i>{" "}
                        {user.email}
                      </p>

                      <p className="fs-12 m-0 pb-2">
                        <i className="fa fa-phone text-green pe-1 ps-sm-2 fs-13"></i>{" "}
                        +{user.phone}
                      </p>
                    </div>
                    <Link to={'/update-user-profile'} state={editProps} className="butn py-2 fs-15">
                      Edit Profile
                    </Link>
                  </div>
                </div>

                <div className="row pe-xl-5 m-0 profile-data">
                  <div className="col-3 pb-3 ps-0">
                    <h3 className="semibold text-green d-flex align-items-center gap-1 ">
                      <img src="assets/img/icon/clipboardtick.svg" alt="" />
                      {props?.user?.user_bookings_count}
                    </h3>
                    <h6 className="regular text-green">Bookings</h6>
                  </div>

                  <div className="col-3 text-center pb-3 ps-0">
                    <h3 className="semibold text-green d-flex align-items-center gap-1 justify-content-center">
                      <img src="assets/img/icon/documenttext.svg" alt="" />
                      {props?.user?.user_posts_count}
                    </h3>
                    <h6 className="regular text-green">Posts</h6>
                  </div>

                  <div className="col-3 text-center pb-3">
                    <h3 className="semibold text-green d-flex align-items-center gap-1 justify-content-center">
                      <img src="assets/img/icon/briefcase.svg" alt="" />
                      {props.user?.userJobsApplied}
                    </h3>
                    <h6 className="regular text-green">Jobs Applied</h6>
                  </div>
                </div>

                <h3 className="medium">About</h3>
                <p className="text-justify text-696969">                  
                  {user.showFull ? user.about : user.about.slice(0, 140)} {user.about.length > 140 &&
                  <a onClick={(e) => {
                    e.preventDefault();
                    let newCoachInfo = { ...user }
                    newCoachInfo['showFull'] = !newCoachInfo.showFull;
                    setUserInfo(newCoachInfo)
                  }} className="text-green c-p"
                  style={{
                    textDecoration: "underline"
                  }}
                  >{user.showFull ? 'Read Less' : 'Read More'}</a>}
                </p>
              </div>
              <Posts user={props.user} pageFrom='user-profile'/>
            </div>
            {/* <!-- END MIDDLE COLUMN --> */}
            <Promotions />
            {/* <!-- END RIGHT COLUMN --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
//export default Header;
export default connect(mapStateToProps, {})(Profile);